import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'common/i18n';
import ChangeUserInfoForm from './ChangeUserInfoForm';
import ProfileImage from './ProfileImage';

import './profile-pane.scss';

const profileUrl = '/profile/' + (window.socrata?.profile?.user?.id || window.socrata?.currentUser?.id);

const ProfilePane = () => {
  return (
    <div className="account-edit-profile-pane">
      <h1>{I18n.t('screens.profile.edit.title')}</h1>
      <ProfileImage />
      <ChangeUserInfoForm />
      <div>
        <a href={profileUrl} className="profile-link">
          {I18n.t('screens.profile.edit.profile_link')}
        </a>
      </div>
    </div>
  );
};

ProfilePane.propTypes = {
  action: PropTypes.string,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.any
};

export default ProfilePane;
