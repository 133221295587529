import React from 'react';

import I18n from 'common/i18n';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';

import AppTokensEditModal from './AppTokensEditModal';

export const AppTokenEditControl = ({
  appToken,
  onDeleteClick,
  onEditClick,
  onEditSubmit,
  busy,
  showModal,
  formErrors
}) => {
  return (
    <div>
      <DropdownButton>
        <DropdownItem onClick={onEditClick}>
          {I18n.t('screens.profile.app_tokens.item.edit_token_link')}
        </DropdownItem>
        <DropdownItem onClick={onDeleteClick}>
          {I18n.t('screens.profile.app_tokens.item.delete')}
        </DropdownItem>
      </DropdownButton>
      {showModal && (
        <AppTokensEditModal
          busy={busy}
          title={I18n.t('screens.profile.edit.app_tokens.edit_app_token')}
          {...appToken}
          formErrors={formErrors}
          onSubmit={onEditSubmit}
        />
      )}
    </div>
  );
};

export default AppTokenEditControl;
