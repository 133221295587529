import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';

import I18n from 'common/i18n';
import emailVerificationApi from 'common/core/email_verification';
import { showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import Button, { VARIANTS } from 'common/components/Button';
import Alert, { AlertType } from 'common/components/Alert';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import showConfirmationDialog from 'common/components/ConfirmationDialog';

import './pending-email-change-info.scss';

interface VerifyEmailChangeModalProps {
  /** Function to call to re-fetch the user's pending email change status */
  refetchEmailVerificationStatus: () => void;
  currentEmail: string;
  pendingEmail: string;
}

/**
 * This modal is shown when a user tries to change their email and email verification is turned on.
 *
 * It shows a message letting them know that
 */
const PendingEmailChangeInfo: FunctionComponent<VerifyEmailChangeModalProps> = ({
  refetchEmailVerificationStatus,
  currentEmail,
  pendingEmail
}) => {
  // resend email query
  const {
    isLoading: busyResending,
    isError: isResendError,
    isSuccess: isResendSuccess,
    refetch: runResendQuery
  } = useQuery(
    'resend-email-change',
    async () => await emailVerificationApi.resendEmailVerification({ userIdentifier: 'current' }),
    {
      // so that this is run by calling the `refetch` function instead of when the component mounts
      enabled: false,

      // don't retry if this fails, and don't cache the response
      retry: false,
      cacheTime: 0,
      onSuccess: () => {
        showSuccessToastNow(I18n.t('account.common.pending_email_change.resend_success'));
      }
    }
  );

  // cancel change query
  const {
    isLoading: busyCancelling,
    isError: isCancelError,
    refetch: runCancelQuery
  } = useQuery('cancel-email-change', async () => await emailVerificationApi.cancelEmailChange(), {
    // so that this is run by calling the `refetch` function instead of when the component mounts
    enabled: false,

    // don't retry if this fails, and don't cache the response
    retry: false,
    cacheTime: 0,

    // close the modal after canceling
    onSuccess: () => {
      showSuccessToastNow(I18n.t('account.common.pending_email_change.successfully_cancelled'));
      refetchEmailVerificationStatus();
    }
  });

  /** Confirm that the user actually wants to cancel and, if they do, run the query */
  const onCancelRequestClick = async () => {
    const confirm = await showConfirmationDialog(
      I18n.t('account.common.pending_email_change.are_you_sure_you_want_to_cancel'),
      'pending-email-change-cancellation-confirmation',
      {
        header: I18n.t('account.common.pending_email_change.cancel_request_confirm_title'),
        agree: {
          className: 'cancel-email-change-modal-button',
          text: I18n.t('account.common.pending_email_change.yes_cancel_email_change'),
          variant: VARIANTS.ERROR
        },
        cancel: {
          className: 'cancel-email-change-modal-button',
          text: I18n.t('account.common.pending_email_change.no_cancel_email_change')
        }
      }
    );

    if (confirm.confirmed) {
      runCancelQuery();
    }
  };

  return (
    <div>
      <div>
        {isResendError && (
          <Alert type={AlertType.Error}>
            {I18n.t('account.common.pending_email_change.error_resending_verification')}
          </Alert>
        )}
        {isCancelError && (
          <Alert type={AlertType.Error}>
            {I18n.t('account.common.pending_email_change.error_canceling_request')}
          </Alert>
        )}

        {/* Current/Pending Email */}
        <div className="pending-email-change--email-label-container">
          <strong className="pending-email-change--email-label">
            {I18n.t('account.common.pending_email_change.current_email')}:
          </strong>
          {currentEmail}
        </div>
        <div className="pending-email-change--email-label-container">
          <strong className="pending-email-change--email-label">
            {I18n.t('account.common.pending_email_change.pending_email')}:
          </strong>
          {pendingEmail}
        </div>
      </div>

      {/* Footer */}
      <div className="pending-email-change--footer">
        {/* Info message */}
        <div className="pending-email-change--message-container">
          <SocrataIcon name={IconName.Warning} className="pending-email-change--warning-icon" />
          <div>
            <p className="pending-email-change--paragraph">
              {I18n.t('account.common.pending_email_change.request_is_pending')}
            </p>
          </div>
        </div>

        {/* Buttons */}
        <div className="pending-email-change--button-container">
          <Button
            className="pending-email-change--cancel-request-button"
            onClick={onCancelRequestClick}
            busy={busyCancelling}
          >
            {I18n.t('account.common.pending_email_change.cancel_request_button')}
          </Button>
          <Button
            variant={VARIANTS.PRIMARY}
            className="pending-email-change--resend-request-button"
            onClick={() => runResendQuery()}
            busy={busyResending}
            disabled={isResendSuccess} // disabled to prevent multiple resends
          >
            {isResendSuccess
              ? I18n.t('account.common.pending_email_change.resend_email_button_sent')
              : I18n.t('account.common.pending_email_change.resend_email_button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PendingEmailChangeInfo;
