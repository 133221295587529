export const OPEN_PASSWORD_FORM = 'OPEN_PASSWORD_FORM';
export const OPEN_EMAIL_FORM = 'OPEN_EMAIL_FORM';
export const CLOSE_PASSWORD_FORM = 'CLOSE_PASSWORD_FORM';
export const CLOSE_EMAIL_FORM = 'CLOSE_EMAIL_FORM';
export const UPDATE_USERS_EMAIL = 'UPDATE_USERS_EMAIL';
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

/** Minimum number of requirements that a password has to meet to be valid */
export const MINIMUM_NEW_PASSWORD_REQUIREMENTS_TO_MEET = 3;
