import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import pick from 'lodash/fp/pick';

import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import TextArea from 'common/components/Forms/TextArea';
import ErrorList from 'common/components/ErrorList';
import Button from 'common/components/Button';
import Checkbox from 'common/components/Forms/Checkbox';
import SocrataIcon from 'common/components/SocrataIcon';

import Tooltip from '../../Tooltip';

const pickAppTokenFields = pick(['name', 'description', 'organization', 'website', 'baseUri', 'public']);

export class AppTokensEditModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    busy: PropTypes.bool,
    name: PropTypes.string,
    description: PropTypes.string,
    formErrors: PropTypes.array,
    organization: PropTypes.string,
    website: PropTypes.string,
    baseUri: PropTypes.string,
    public: PropTypes.bool,
    // Will be called with the AppToken object, or null if cancelled/dismissed
    onSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {
    busy: false,
    public: false
  };

  state = {
    formIsDirty: this.props.name && this.props.description,
    name: this.props.name,
    validName: true,
    description: this.props.description,
    validDescription: true,
    organization: this.props.organization,
    website: this.props.website,
    baseUri: this.props.baseUri,
    public: this.props.public
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { validName, validDescription } = this.state;
    if (validName && validDescription) {
      this.props.onSubmit(pickAppTokenFields(this.state));
    }
  };

  onCancel = () => {
    this.props.onSubmit(false);
  };

  render() {
    const { busy, formErrors, title } = this.props;
    const { baseUri, description, formIsDirty, name, organization, validDescription, validName, website } =
      this.state;
    const isValid = formIsDirty && validName && validDescription;
    return (
      <Form onSubmit={this.onSubmit} className="app-tokens-create-form">
        <Modal className="app-tokens__edit-modal" onDismiss={this.onCancel}>
          <ModalHeader onDismiss={this.onCancel}>
            <h2>{title}</h2>
          </ModalHeader>

          <ModalContent>
            <Input
              disabled={busy}
              label={I18n.t('screens.profile.edit.app_tokens.application_name')}
              name="name"
              valid={validName}
              value={name}
              onChange={(e) =>
                this.setState({ name: e.target.value, validApplicationName: true, formIsDirty: true })
              }
              onFocus={() => this.setState({ validName: true, formIsDirty: true })}
              onBlur={(e) => this.setState({ validName: !isEmpty(e.target.value), formIsDirty: true })}
              required
            />
            <TextArea
              disabled={busy}
              label={I18n.t('screens.profile.edit.app_tokens.description')}
              name="description"
              valid={validDescription}
              value={description}
              onChange={(e) =>
                this.setState({ description: e.target.value, validDescription: true, formIsDirty: true })
              }
              onFocus={() => this.setState({ validDescription: true, formIsDirty: true })}
              onBlur={(e) => this.setState({ validDescription: !isEmpty(e.target.value), formIsDirty: true })}
              required
            />
            <Input
              disabled={busy}
              label={I18n.t('screens.profile.edit.app_tokens.organization')}
              name="organization"
              value={organization}
              onChange={(e) => this.setState({ organization: e.target.value })}
            />
            <Input
              disabled={busy}
              label={I18n.t('screens.profile.edit.app_tokens.website')}
              name="website"
              value={website}
              onChange={(e) => this.setState({ website: e.target.value })}
            />
            <Input
              disabled={busy}
              label={I18n.t('screens.profile.edit.app_tokens.callback_prefix')}
              name="baseUri"
              value={baseUri}
              onChange={(e) => this.setState({ baseUri: e.target.value })}
            >
              <Tooltip
                renderTooltip={() => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: I18n.t('screens.profile.edit.app_tokens.callback_prefix_help_html')
                    }}
                  />
                )}
              >
                <SocrataIcon name="question-inverse" />
              </Tooltip>
            </Input>
            <Checkbox
              disabled={busy}
              name="is_public"
              label={I18n.t('screens.profile.edit.app_tokens.public')}
              checked={this.state.public}
              onChange={(e) => this.setState({ public: e.target.checked })}
            />
            <ErrorList errors={formErrors} />
          </ModalContent>

          <ModalFooter>
            <div>
              <Button disabled={busy} type="button" className="cancel-button" onClick={this.onCancel}>
                {I18n.t('core.dialogs.cancel')}
              </Button>{' '}
              <Button
                disabled={!isValid || busy}
                busy={busy}
                type="submit"
                variant="primary"
                className="add-user-confirm"
              >
                {I18n.t('core.dialogs.save')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </Form>
    );
  }
}

export default AppTokensEditModal;
