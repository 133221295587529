import _ from 'lodash';

import React, { Component } from 'react';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import Form from 'common/components/Forms/Form';

const scope = 'screens.profile.edit.account.email';

export class EmailNotifications extends Component {
  renderInputElements = () => {
    return _.map(_.get(window, 'socrata.emailInterest', {}), (entry, key) => {
      const isSelected = _.includes(
        _.map(_.get(window, 'socrata.currentUser.emailInterest'), 'eventTag'),
        key
      );
      const inputName = `email_interests[${key}]`;

      return (
        <li key={entry.description}>
          <label>
            <span>
              <input type="checkbox" ref="buyao" name={inputName} value="true" defaultChecked={isSelected} />
            </span>
            {I18n.t(`interests.${entry.description}`, { scope })}
          </label>
        </li>
      );
    });
  };

  render() {
    const userId = _.get(window, 'socrata.currentUser.id');
    const formAction = `/profile/${userId}/update_account`;

    return (
      <div id="email-notifications">
        <hr />
        <h2 id="email-notifications-section-legend" tabIndex="0">
          {I18n.t('title', { scope })}
        </h2>
        <Form action={formAction} method="post" ref={(el) => (this.form = el)}>
          <ul role="list" aria-labelledby="email-notifications-section-legend">
            {this.renderInputElements()}
          </ul>
          <Button type="submit" className="save" size="sm" variant="primary">
            {I18n.t('core.dialogs.save')}
          </Button>
        </Form>
      </div>
    );
  }
}

export default EmailNotifications;
