import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/AccessibleModal';
import Button, { VARIANTS } from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import Alert, { AlertType } from 'common/components/Alert';

import { createKey } from './Redux/actions.js';
import { getCreateKeyError } from './Redux/selectors.js';
import { API_KEY_ERROR_CODES } from './ApiKeysConstants';

type CreateKeyError = {
  code: API_KEY_ERROR_CODES;
  error: boolean;
  message: string;
};

interface OwnProps {
  /* passed in to close the modal when we're done */
  onClose: () => void;
}

interface StateProps {
  /*redux store info */
  createKeyError: CreateKeyError;
}

interface DispatchProps {
  /* redux dispatch */
  onCreateKey: (name: string) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

/**
 * This modal has an input box that lets the user create a new API key.
 * It also handles parsing errors that come back from core and showing them in a user-friendly way.
 */
export class ApiKeyCreateModal extends Component<Props> {
  state = {
    nameValue: ''
  };

  onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { onCreateKey } = this.props;
    const { nameValue } = this.state;

    e.preventDefault();

    onCreateKey(nameValue);
  };

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ nameValue: e.target.value });

  getErrorMessage = (createKeyError: CreateKeyError) => {
    switch (createKeyError?.code) {
      case API_KEY_ERROR_CODES.TOKEN_LIMIT_REACHED:
        return I18n.t('screens.profile.edit.api_keys.errors.token_limit_reached');
      case API_KEY_ERROR_CODES.NAME_TAKEN:
        return I18n.t('screens.profile.edit.api_keys.errors.name_taken');
      case API_KEY_ERROR_CODES.NAME_TOO_LONG:
        return I18n.t('screens.profile.edit.api_keys.errors.name_too_long');
      default:
        return I18n.t('screens.profile.edit.api_keys.errors.unknown_create', {
          requestId: get(createKeyError, 'requestId', '(unknown)')
        });
    }
  };

  render() {
    const { onClose, createKeyError } = this.props;
    const { nameValue } = this.state;

    return (
      <Modal
        onRequestClose={onClose}
        contentLabel={I18n.t('screens.profile.edit.api_keys.create_key_modal.title')}
      >
        <ModalHeader showCloseButton={false}>
          {I18n.t('screens.profile.edit.api_keys.create_title')}
        </ModalHeader>
        <Form onSubmit={this.onFormSubmit}>
          <ModalContent>
            <Alert show={!isEmpty(createKeyError)} type={AlertType.Error}>
              {this.getErrorMessage(createKeyError)}
            </Alert>
            <Input
              autoFocus
              label={I18n.t('screens.profile.edit.api_keys.create_name_label')}
              name="name"
              valid={!createKeyError}
              value={nameValue}
              onChange={this.onInputChange}
            />
          </ModalContent>

          <ModalFooter>
            <Button onClick={onClose}>{I18n.t('screens.profile.edit.api_keys.cancel')}</Button>{' '}
            <Button variant={VARIANTS.PRIMARY} type="submit">
              {I18n.t('screens.profile.edit.api_keys.create')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

//Once the redux store is converted to TypeScript this should be changed.
const mapStateToProps = (state: any) => ({
  createKeyError: getCreateKeyError(state)
});

const mapDispatchToProps = {
  onCreateKey: createKey
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ApiKeyCreateModal);
