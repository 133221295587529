import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import DragDropUpload from 'common/components/DragDropUpload';

const scope = 'screens.profile.edit.image';

export class ProfileImageUpload extends Component {
  static messages = {
    buttonLabel: I18n.t('button_label', { scope }),
    title: I18n.t('drag_and_drop', { scope }),
    subtitle: I18n.t('file_types', { scope })
  };

  // TODO: Do we need to internationalize these file type descriptions?
  static acceptedFileTypes = [
    { extension: 'jpg', mimeType: 'image/jpeg', description: I18n.t('jpeg_description', { scope }) },
    { extension: 'png', mimeType: 'image/png', description: I18n.t('png_description', { scope }) },
    { extension: 'gif', mimeType: 'image/gif', description: I18n.t('gif_description', { scope }) }
  ];

  handleFile = (upload) => {
    const { handleUpload, setUploadedImage } = this.props;
    const reader = new FileReader();

    handleUpload(upload.file);
    reader.onload = (e) => setUploadedImage && setUploadedImage(e.target.result);
    reader.readAsDataURL(upload.file);
  };

  render() {
    return (
      <DragDropUpload
        onDrop={this.handleFile}
        messages={ProfileImageUpload.messages}
        acceptedFileTypes={ProfileImageUpload.acceptedFileTypes}
      />
    );
  }
}

ProfileImageUpload.propTypes = {
  handleUpload: PropTypes.func.isRequired, // Sets the array of file names uploaded (usually just one file)
  setUploadedImage: PropTypes.func // Sets the data:image/jpeg;base64 content of image itself
};
