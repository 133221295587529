import PropTypes from 'prop-types';

import RoutePropType from './RoutePropType';
import UserPropType from 'common/propTypes/UserPropType';

// This is what gets passed in to the component, generated in the profile_helper.rb file
export default PropTypes.shape({
  currentUser: UserPropType.isRequired,
  domain: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  localePrefix: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  routes: PropTypes.objectOf(RoutePropType)
});
