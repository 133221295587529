import React, { Component } from 'react';
import { connect } from 'react-redux';

import { get, isEmpty, trim } from 'lodash';

import { defaultHeaders } from 'common/http';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import Button from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import TextArea from 'common/components/Forms/TextArea';
import I18n from 'common/i18n';

import { getCurrentUser } from '../../selectors';
import userPropType from '../../propTypes/configUser';
import { UPDATE_USER_STATE } from '../../constants';

import '../account-form-input.scss';

export class ChangeUserInfoForm extends Component {
  state = {
    description: '',
    displayName: '',
    organization: '',
    title: ''
  };

  constructor(props) {
    super(props);
    const { user } = props;
    this.state = { ...this.state, ...user };
  }
  // Function that takes a key and is passed the event of an input or
  // the object of { value, name } to set the appropriate state
  handleChange = (key) => (event) => this.setState({ [key]: get(event, 'target.value', '') });

  static failedToUpdateToast(failureMessage = I18n.t('account.common.form.failed_updating_information')) {
    showToastNow({
      type: ToastType.ERROR,
      content: failureMessage
    });
  }

  // valid if blank or contains something other than whitespace
  static isFieldValid(field) {
    return isEmpty(field) || !isEmpty(trim(field));
  }

  areFieldsValid = () => {
    const { description, displayName, organization, title } = this.state;
    // Does a field have content that isn't exclusively whitespace
    const isFieldValid = ChangeUserInfoForm.isFieldValid;
    // Form is valid if and only if at least one field has a value
    return (
      isFieldValid(description) ||
      isFieldValid(displayName) ||
      isFieldValid(organization) ||
      isFieldValid(title)
    );
  };

  canTryToSubmit = () => {
    const { displayName } = this.state;
    // Form is valid if and only displayName is present because displayName is a required user property
    return !isEmpty(trim(displayName));
  };

  sendUpdatedUserInfo = async () => {
    let { organization, title, description, displayName } = this.state;
    organization = trim(organization);
    title = trim(title);
    description = trim(description);
    displayName = trim(displayName);
    const payload = { organization, title, description, displayName };
    const rawResponse = await fetch('/api/users/current', {
      headers: defaultHeaders,
      method: 'PATCH',
      body: JSON.stringify(payload)
    });
    const response = await rawResponse.json();
    if (rawResponse.status === 200 || rawResponse.status === 201) {
      showToastNow({
        type: ToastType.SUCCESS,
        content: I18n.t('account.common.form.success_updating_information')
      });
    } else {
      ChangeUserInfoForm.failedToUpdateToast(response.message);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.areFieldsValid()) {
      this.sendUpdatedUserInfo();
      this.updateSubmittedData();
    } else {
      showToastNow({
        type: ToastType.ERROR,
        content: I18n.t('account.common.validation.empty_fields')
      });
    }
  };

  updateSubmittedData = () => {
    const { updateUser } = this.props;
    let { description, displayName, organization, title } = this.state;
    const isFieldValid = ChangeUserInfoForm.isFieldValid;
    description = trim(description);
    displayName = trim(displayName);
    organization = trim(organization);
    title = trim(title);
    const newState = {
      displayName: isFieldValid(displayName) ? displayName : '',
      description: isFieldValid(description) ? description : '',
      organization: isFieldValid(organization) ? organization : '',
      title: isFieldValid(title) ? title : ''
    };

    updateUser(newState);
    this.setState(newState);
  };

  render() {
    const { description, displayName, organization, title } = this.state;

    return (
      <div className="account-edit-wrapper">
        <Form onSubmit={this.handleSubmit}>
          <Input
            label={I18n.t('account.common.form.display_name')}
            name="displayName"
            value={displayName}
            onChange={this.handleChange('displayName')}
            placeholder={I18n.t('account.common.form.display_name')}
          />
          <TextArea
            label={I18n.t('account.common.form.description')}
            name="description"
            value={description}
            onChange={this.handleChange('description')}
            placeholder={I18n.t('account.common.form.description')}
            type="textarea"
          />
          <Input
            label={I18n.t('account.common.form.organization')}
            name="organization"
            value={organization}
            onChange={this.handleChange('organization')}
            placeholder={I18n.t('account.common.form.organization')}
          />
          <Input
            label={I18n.t('account.common.form.title')}
            name="title"
            value={title}
            onChange={this.handleChange('title')}
            placeholder={I18n.t('account.common.form.title')}
          />
          <Button type="submit" disabled={!this.canTryToSubmit()} variant="primary">
            {I18n.t('account.common.form.save')}
          </Button>
        </Form>
      </div>
    );
  }
}

ChangeUserInfoForm.propTypes = {
  user: userPropType
};

const mapStateToProps = (state) => ({
  user: getCurrentUser(state)
});

const mapDispatchToProps = {
  updateUser: (newState) => ({ type: UPDATE_USER_STATE, newState })
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserInfoForm);
