import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import I18n from 'common/i18n';
import Alert, { AlertType } from 'common/components/Alert';
import type CoreErrors from 'common/types/coreError';

import { getError } from './Redux/selectors.js';
import { API_KEY_ERROR_CODES } from './ApiKeysConstants';

interface OwnProps {}

interface DispatchProps {}

interface StateProps {
  error: CoreErrors;
}

type Props = StateProps & DispatchProps & OwnProps;

class ApiKeysErrorMessage extends Component<Props> {
  getErrorMessage = (error: CoreErrors): string => {
    switch (get(error, 'json.code')) {
      case API_KEY_ERROR_CODES.AUTHENTICATION_REQUIRED:
        return I18n.t('screens.profile.edit.api_keys.errors.authentication_required_html');
      case API_KEY_ERROR_CODES.INVALID_SORT_COLUMN:
        return I18n.t('screens.profile.edit.api_keys.errors.invalid_sort_column');
      case API_KEY_ERROR_CODES.INVALID_PAGE_SIZE:
        return I18n.t('screens.profile.edit.api_keys.errors.invalid_page_size');
      default:
        return I18n.t('screens.profile.edit.api_keys.errors.unknown_fetch', {
          requestId: get(error, 'requestId', '(unknown)')
        });
    }
  };

  render() {
    const { error } = this.props;
    return (
      <Alert type={AlertType.Error} show={!!error}>
        {this.getErrorMessage(error)}
      </Alert>
    );
  }
}

const mapStateToProps = (state: any) => ({
  error: getError(state)
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(ApiKeysErrorMessage);
