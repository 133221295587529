import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import { invokeArgsMapValues } from 'common/functional_helpers';

import { goToNextPage, goToPreviousPage } from './Redux/actions';
import { getPreviousSeeks, getHasNextPage } from './Redux/selectors';

class ApiKeysTablePagination extends Component {
  static propTypes = {
    // redux props
    previousSeeks: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasNextPage: PropTypes.bool.isRequired,

    // redux actions
    doGoToNextPage: PropTypes.func.isRequired,
    doGoToPreviousPage: PropTypes.func.isRequired
  };
  render() {
    const { doGoToNextPage, doGoToPreviousPage, previousSeeks, hasNextPage } = this.props;

    return (
      <div>
        <Button onClick={doGoToPreviousPage} disabled={previousSeeks.length == 0}>
          {I18n.t('screens.profile.edit.api_keys.previous')}
        </Button>
        <Button disabled={!hasNextPage} onClick={doGoToNextPage}>
          {I18n.t('screens.profile.edit.api_keys.next')}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = invokeArgsMapValues({
  previousSeeks: getPreviousSeeks,
  hasNextPage: getHasNextPage
});

const mapDispatchToProps = {
  doGoToNextPage: goToNextPage,
  doGoToPreviousPage: goToPreviousPage
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeysTablePagination);
