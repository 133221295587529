import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button, { SIZES, VARIANTS } from 'common/components/Button';

export default class ClickToView extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    renderValue: PropTypes.func
  };

  static defaultProps = {
    children: 'Click to View',
    onClick: () => {},
    renderValue: (value) => <span>{value}</span>
  };

  state = {
    busy: false,
    value: null
  };

  setValue = (value) => {
    this.setState({ value, busy: false });
  };

  onClick = (e) => {
    const { onClick } = this.props;
    e.preventDefault();
    this.setState({ busy: true }, () => onClick(this.setValue));
  };

  render() {
    const { renderValue, ...props } = this.props;
    const { busy, value } = this.state;
    if (value) {
      return renderValue(value);
    } else {
      return (
        <Button {...props} busy={busy} size={SIZES.SMALL} variant={VARIANTS.DEFAULT} onClick={this.onClick} />
      );
    }
  }
}
