import isEmpty from 'lodash/isEmpty';

import I18n from 'common/i18n';

import newPasswordValidation from './newPasswordValidation';
import { State as ChangePasswordFormState } from './ChangePasswordForm';
import { PasswordRequirements } from '../../types';

const validatePasswordChangeInState = (
  state: ChangePasswordFormState,
  newPasswordRequirements: PasswordRequirements,
  validateRequired = false
): ChangePasswordFormState => {
  const updatedState: ChangePasswordFormState = {
    ...state,
    currentPassword: {
      ...state.currentPassword,
      valid: true,
      errorMessage: undefined
    },
    newPassword: {
      ...state.newPassword,
      valid: true,
      errorMessage: undefined
    },
    newPasswordConfirm: {
      ...state.newPasswordConfirm,
      valid: true,
      errorMessage: undefined
    }
  };

  const password = updatedState.currentPassword.value as string | undefined;
  const newPass = updatedState.newPassword.value as string | undefined;
  const confirmedValue = updatedState.newPasswordConfirm.value as string | undefined;

  const newPassSameAsCurrentPass = password && newPass && password === newPass;

  const newPasswordNotMatchingComplexityRequirements = newPass
    ? !newPasswordValidation(newPass, newPasswordRequirements)
    : false;

  if (newPassSameAsCurrentPass) {
    updatedState.newPassword.valid = false;
    updatedState.newPassword.errorMessage = I18n.t(
      'account.common.validation.current_and_new_match_password'
    );
  } else if (newPasswordNotMatchingComplexityRequirements) {
    updatedState.newPassword.valid = false;
    updatedState.newPassword.errorMessage = I18n.t('account.common.validation.new_pass_not_complex');
  }

  const newPassDoesNotMatch = newPass && confirmedValue && newPass !== confirmedValue;
  if (newPassDoesNotMatch) {
    updatedState.newPasswordConfirm.valid = false;
    updatedState.newPasswordConfirm.errorMessage = I18n.t('account.common.validation.mismatch');
  }

  if (validateRequired) {
    if (isEmpty(password)) {
      updatedState.currentPassword.valid = false;
      updatedState.currentPassword.errorMessage = I18n.t('core.validation.enter_a_value');
    }

    if (isEmpty(newPass)) {
      updatedState.newPassword.valid = false;
      updatedState.newPassword.errorMessage = I18n.t('core.validation.enter_a_value');
    }

    if (isEmpty(confirmedValue)) {
      updatedState.newPasswordConfirm.valid = false;
      updatedState.newPasswordConfirm.errorMessage = I18n.t('core.validation.enter_a_value');
    }
  }

  return updatedState;
};

export default validatePasswordChangeInState;
