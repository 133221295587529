import React from 'react';

import AccountEditorNavigation from './AccountEditorNavigation';

import './account-editor-navigation.scss';

const AccountEditor = ({ children }) => (
  <div className="account-editor-container">
    <AccountEditorNavigation />
    <div className="account-editor-pane-content">{children}</div>
  </div>
);

export default AccountEditor;
