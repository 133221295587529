import PropTypes from 'prop-types';

import { SORT_DIRECTION } from 'common/Constants';

import {
  CREATED_AT_COLUMN_NAME,
  DELETED_AT_COLUMN_NAME,
  NAME_COLUMN_NAME,
  LAST_USED_AT_COLUMN_NAME
} from './ApiKeysConstants';

export const SortByPropType = PropTypes.oneOf([
  CREATED_AT_COLUMN_NAME,
  DELETED_AT_COLUMN_NAME,
  NAME_COLUMN_NAME,
  LAST_USED_AT_COLUMN_NAME
]);

export const SortOrderPropType = PropTypes.oneOf([SORT_DIRECTION.ASC, SORT_DIRECTION.DESC]);

export const SearchParamsPropType = PropTypes.shape({
  limit: PropTypes.number.isRequired,
  sortBy: SortByPropType.isRequired,
  sortOrder: SortOrderPropType.isRequired,
  seek: PropTypes.string,
  nameSearch: PropTypes.string,
  includeDeleted: PropTypes.bool.isRequired
});

export const ApiKeyPropType = PropTypes.shape({
  createdAtTimestamp: PropTypes.instanceOf(Date).isRequired,
  lastUsedAtTimestamp: PropTypes.instanceOf(Date),
  keyId: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  keySecret: PropTypes.string, // only comes back if the key is newly created
  deletedAt: PropTypes.instanceOf(Date),

  // for most purposes, owner here should really only be the current user
  owner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  })
});
