import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import last from 'lodash/last';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import classNames from 'classnames';

import I18n from 'common/i18n';

import { getRoutes } from '../selectors';

import { generatePathWithLocale } from '../Utils';

const AccountEditorNavigation = ({ routes: currentRoutes, configRoutes: routes }) => (
  <ul className="nav-sidebar">
    {routes &&
      values(
        mapValues(routes, (value, key) => {
          const classes = classNames(
            {
              current: value.path == last(currentRoutes).path
            },
            'sidebar-item'
          );
          const text = I18n.t(value.translationKey);
          return (
            <li className={classes} key={key}>
              <Link to={generatePathWithLocale(value.path, I18n.locale)}>{text}</Link>
            </li>
          );
        })
      )}
  </ul>
);

const mapStateToProps = (state) => ({
  configRoutes: getRoutes(state)
});

export default withRouter(connect(mapStateToProps)(AccountEditorNavigation));
