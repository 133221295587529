import React, { Component, PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import { currentUserHasFlag } from 'common/current_user';
import UserFlags from 'common/types/users/flags';

import { getConfig, getPasswordFormState, getEmailFormState, getPasswordChanged } from '../selectors.js';
import * as Actions from '../actions';
import ChangeEmailFormWrapper from './ChangeEmail/ChangeEmailFormWrapper';
import ChangePasswordFormWrapper from './ChangePassword/ChangePasswordFormWrapper';

import './account-editor.scss';
import './account-form-input.scss';

interface StateProps {
  config: any;
  passwordFormOpen: boolean;
  emailFormOpen: boolean;
  isPasswordChanged: boolean;
}

interface DispatchProps {
  openPasswordForm: () => void;
  openEmailForm: () => void;
  passwordChanged: (changed: boolean) => void;
}

interface OwnProps {
  action: string;
  method: string;
  onSubmit: () => void;
}

type AccountSettingsPaneProps = PropsWithChildren<StateProps & DispatchProps & OwnProps>;

export class AccountSettingsPane extends Component<AccountSettingsPaneProps> {
  handlePasswordChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const { openPasswordForm, passwordChanged } = this.props;
    e.preventDefault();
    openPasswordForm();
    passwordChanged(false);
  };

  handleEmailChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const { openEmailForm } = this.props;
    e.preventDefault();
    openEmailForm();
  };

  render() {
    const { children, config, passwordFormOpen, emailFormOpen, isPasswordChanged } = this.props;
    const emailAddress = config.currentUser.email;

    // this means that the user does not currently have a password, which means
    // that they can only login to their account via SSO.
    // If this is the case, we show them a message telling them to contact support to change their email.
    const userIsSsoOnly = currentUserHasFlag(UserFlags.NoPassword);

    return (
      <div>
        <h1>{I18n.t('account.common.form.security')}</h1>
        <ChangeEmailFormWrapper
          emailAddress={emailAddress}
          emailFormOpen={emailFormOpen}
          userIsSsoOnly={userIsSsoOnly}
          handleEmailChange={this.handleEmailChange}
        />

        <ChangePasswordFormWrapper
          passwordFormOpen={passwordFormOpen}
          isPasswordChanged={isPasswordChanged}
          userIsSsoOnly={userIsSsoOnly}
          handlePasswordChange={this.handlePasswordChange}
        />
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  config: getConfig(state),
  passwordFormOpen: getPasswordFormState(state),
  emailFormOpen: getEmailFormState(state),
  isPasswordChanged: getPasswordChanged(state)
});

const mapDispatchToProps = {
  openPasswordForm: Actions.openPasswordForm,
  openEmailForm: Actions.openEmailForm,
  passwordChanged: Actions.passwordChanged
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsPane);
