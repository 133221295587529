import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/fp/map';
import matches from 'lodash/fp/matches';
import reject from 'lodash/fp/reject';

import I18n from 'common/i18n';
import { showSuccessToastNow, showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import SocrataIcon from 'common/components/SocrataIcon';

import SecretToken from './SecretToken';
import AppTokenEditControl from './AppTokenEditControl';
import AppTokenCreateButton from './AppTokenCreateButton';
import {
  createAppToken,
  deleteAppToken,
  fetchAppTokens,
  updateAppToken,
  getAppTokenError
} from './AppTokenUtils';
import ResultsTableButtonBar from '../../ResultsTableButtonBar';
import ActualTable from 'account/components/AppTokensTable/AppTokensTable';

class AppTokensTable extends Component {
  static propTypes = {
    action: PropTypes.string,
    method: PropTypes.string,
    onSubmit: PropTypes.func,
    children: PropTypes.any,
    userUid: PropTypes.string.isRequired
  };

  state = {
    appTokens: [],
    busy: false,
    formErrors: [],
    showCreateModal: false,
    editingAppTokenId: null
  };

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    const { userUid } = this.props;
    fetchAppTokens(userUid).then(
      (appTokens) => {
        this.setState({ appTokens });
      },
      () => showErrorToastNow(I18n.t('screens.profile.edit.app_tokens.core_error.null_error_code_html'))
    );
  };

  deleteAppToken = (id) => {
    const { userUid } = this.props;
    if (window.confirm(I18n.t('screens.profile.edit.app_tokens.delete_confirm'))) {
      deleteAppToken(userUid, id).then(
        () => {
          this.setState(({ appTokens }) => ({ appTokens: reject(matches({ id }), appTokens) }));
          showSuccessToastNow(I18n.t('screens.profile.edit.app_tokens.delete_success'));
        },
        ({ message }) =>
          showSuccessToastNow(I18n.t('screens.profile.edit.app_tokens.delete_error', { message }))
      );
    }
  };

  handleError = (appName) => (error) => {
    this.setState({ busy: false });
    const translationKey = error.json
      ? getAppTokenError(error.json)
      : 'screens.profile.edit.app_tokens.core_error.null_error_code_html';
    this.setState({
      formErrors: [{ translationKey, app_name: appName }]
    });
  };

  onEdit = (tokenId) => (data) => {
    this.setState({ formErrors: [] });

    if (data) {
      const { userUid } = this.props;
      const { name } = data;
      this.setState({ busy: true });
      updateAppToken(userUid, tokenId, data)
        .then((updatedAppToken) => {
          this.setState(({ appTokens }) => ({
            appTokens: map((appToken) => (appToken.id === tokenId ? updatedAppToken : appToken), appTokens),
            busy: false,
            editingAppTokenId: null
          }));
          showSuccessToastNow(I18n.t('screens.profile.edit.app_tokens.edit_success'));
        })
        .catch(this.handleError(name));
    } else {
      this.setState({ editingAppTokenId: null });
    }
  };

  renderEditControl = (id, appToken) => {
    const { busy, editingAppTokenId, formErrors } = this.state;
    return (
      <AppTokenEditControl
        appToken={appToken}
        busy={busy}
        formErrors={formErrors}
        onEditClick={() => this.setState({ formErrors: [], editingAppTokenId: id })}
        onDeleteClick={() => this.deleteAppToken(id)}
        onEditSubmit={this.onEdit(id)}
        showModal={editingAppTokenId === id}
      />
    );
  };

  onCreate = (data) => {
    this.setState({ formErrors: [] });

    if (data) {
      const { name } = data;
      const { userUid } = this.props;
      this.setState({ busy: true });
      createAppToken(userUid, data)
        .then((appToken) => {
          this.setState(({ appTokens }) => ({
            appTokens: [...appTokens, appToken],
            busy: false,
            showCreateModal: false
          }));
          showSuccessToastNow(I18n.t('screens.profile.edit.app_tokens.create_success'));
        })
        .catch(this.handleError(name));
    } else {
      this.setState({ showCreateModal: false });
    }
  };

  renderCreateButton = () => {
    const { busy, showCreateModal, formErrors } = this.state;
    return (
      <AppTokenCreateButton
        showModal={showCreateModal}
        formErrors={formErrors}
        busy={busy}
        onClick={() => this.setState({ formErrors: [], showCreateModal: true })}
        onSubmit={this.onCreate}
      />
    );
  };

  renderHeader = () => (
    <div>
      <h2>{I18n.t('screens.profile.app_tokens.table_title')}</h2>
      <details className="developer-settings__details">
        <summary>
          {I18n.t('screens.profile.edit.app_tokens.intro_summary')} <SocrataIcon name="question" />
        </summary>
        <p>{I18n.t('screens.profile.edit.app_tokens.intro')}</p>
        <p
          dangerouslySetInnerHTML={{ __html: I18n.t('screens.profile.edit.api_keys.intro_dev_link_html') }}
        />
      </details>
    </div>
  );

  render() {
    const { appTokens } = this.state;
    const { userUid } = this.props;
    return (
      <div>
        {this.renderHeader()}
        <ResultsTableButtonBar endActions={this.renderCreateButton()} />
        <ActualTable
          appTokens={appTokens}
          editControlComponent={this.renderEditControl}
          showTokens={true}
          secretTokenComponent={SecretToken}
          userUid={userUid}
        />
      </div>
    );
  }
}

export default AppTokensTable;
