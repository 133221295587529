export const PAGE_SIZE = 10;
export const CREATED_AT_COLUMN_NAME = 'created_at';
export const DELETED_AT_COLUMN_NAME = 'deleted_at';
export const LAST_USED_AT_COLUMN_NAME = 'last_used_at';
export const NAME_COLUMN_NAME = 'name';

export enum API_KEY_ERROR_CODES {
  AUTHENTICATION_REQUIRED = 'authentication_required',
  TOKEN_LIMIT_REACHED = 'API_KEY.TOKEN_LIMIT_REACHED',
  NAME_TAKEN = 'API_KEY.NAME_TAKEN',
  NAME_TOO_LONG = 'API_KEY.NAME_TOO_LONG',
  INVALID_SORT_COLUMN = 'API_KEY.INVALID_SORT_COLUMN',
  INVALID_PAGE_SIZE = 'API_KEY.INVALID_PAGE_SIZE'
}
