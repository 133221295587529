import { PasswordRequirements } from '../../types';

/**
 * Collection of default password complexity rules.
 * Currently the only rule that must be met is the password length.
 * While only x others must be met.
 */
const generatePasswordRequirements = (minLength: number, maxLength: number): PasswordRequirements => ({
  hasLength: {
    name: 'len',
    mustMeet: true,
    validate: (toTest: string): boolean => toTest.length >= minLength && toTest.length <= maxLength
  },
  containsDigit: {
    name: 'dig',
    mustMeet: false,
    validate: (toTest: string): boolean => /\d/.test(toTest)
  },
  containsUpperCase: {
    name: 'upper',
    mustMeet: false,
    validate: (toTest: string): boolean => /[A-Z]/.test(toTest)
  },
  containsLowerCase: {
    name: 'lower',
    mustMeet: false,
    validate: (toTest: string): boolean => /[a-z]/.test(toTest)
  },
  containsNonAlphaNumbSymbol: {
    name: 'sym',
    mustMeet: false,
    validate: (toTest: string): boolean => /[^a-zA-Z0-9]/.test(toTest)
  }
});

export default generatePasswordRequirements;
