import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './results-table-button-bar.scss';

export default class ResultsTableButtonBar extends Component {
  static propTypes = {
    startActions: PropTypes.any,
    endActions: PropTypes.any
  };

  static defaultProps = {
    startActions: null,
    endActions: null
  };

  render() {
    const { startActions, endActions } = this.props;
    return (
      <div className="results-table-button-bar">
        <div className="results-table-button-bar__start-actions">{React.Children.toArray(startActions)}</div>
        <div className="results-table-button-bar__end-actions">{React.Children.toArray(endActions)}</div>
      </div>
    );
  }
}
