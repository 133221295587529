import React, { Component } from 'react';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import getCsrfToken from 'common/js_utils/getCsrfToken';
import { appToken as getAppToken } from 'common/http';
import { getCurrentUser } from 'common/current_user';
import { DefaultProfileImage } from 'common/components/DefaultProfileImage';
import { ProfileImageUpload } from './ProfileImageUpload';

import './profile-image.scss';

const scope = 'screens.profile.edit.image';

export class ProfileImage extends Component {
  state = {
    busy: false,
    error: false,
    errorDetails: null,
    imageModalVisible: false,
    profileImage: getCurrentUser().profileImageUrlLarge,
    uploadedFile: null,
    uploadedImage: null
  };
  static DEFAULT_IMAGE = '/images/large-profile.png';

  getProfileImageUploadUrl = () => {
    const userId = getCurrentUser().id;

    return `/users/${userId}/profile_images`;
  };

  renderModalContent = () => {
    const { busy, error, errorDetails, uploadedImage } = this.state;

    if (busy) {
      return <span className="spinner-default spinner-large"></span>;
    }

    if (error) {
      return <span className="error">{I18n.t('error', { scope, message: errorDetails })}</span>;
    }

    if (uploadedImage) {
      return (
        <div className="image-preview-container">
          <img src={uploadedImage} />
        </div>
      );
    }

    return (
      <ProfileImageUpload
        setUploadedImage={(image) => this.setState({ uploadedImage: image })}
        handleUpload={(file) => this.setState({ uploadedFile: file })}
      />
    );
  };

  renderImageModal = () => {
    const { error, uploadedImage } = this.state;
    const disabled = error || !uploadedImage;

    return (
      <Modal className="profile-image-modal" onDismiss={this.onImageCancel}>
        <ModalHeader title="Upload Photo" onDismiss={this.onImageCancel} showCloseButton={false} />
        <ModalContent className="profile-image-modal-content">{this.renderModalContent()}</ModalContent>
        <ModalFooter className="profile-image-modal-modal-footer">
          <div className="profile-image-modal-actions">
            <Button className="cancel" size="sm" onClick={this.onImageCancel}>
              {I18n.t('cancel', { scope })}
            </Button>
            <Button
              className="save"
              size="sm"
              variant="primary"
              onClick={this.onImageSave}
              disabled={disabled}
            >
              {I18n.t('save', { scope })}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  onImageCancel = () => {
    this.setState({
      imageModalVisible: false,
      busy: false,
      error: false,
      errorDetails: null,
      uploadedFile: null,
      uploadedImage: null
    });
  };

  onImageSave = async () => {
    const { uploadedFile, uploadedImage } = this.state;
    const formData = new FormData();

    this.setState({ busy: true });
    formData.append('avatar', uploadedFile);

    const response = await fetch(this.getProfileImageUploadUrl(), {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'X-App-Token': getAppToken()
      }
    });

    if (response.ok) {
      this.setState({
        busy: false,
        error: false,
        errorDetails: null,
        imageModalVisible: false,
        profileImage: uploadedImage
      });
    } else {
      try {
        response
          .json()
          .then((json) => {
            this.setState({ busy: false, error: true, errorDetails: json.message });
          })
          .catch(() => {
            this.setState({ busy: false, error: true, errorDetails: 'Invalid response from server' });
          });
      } catch (error) {
        this.setState({ busy: false, error: true, errorDetails: error });
      }
    }
  };

  showUploadModal = () =>
    this.setState({
      imageModalVisible: true,
      busy: false,
      error: false,
      errorDetails: null,
      uploadedFile: null,
      uploadedImage: null
    });

  render() {
    const { profileImage, imageModalVisible } = this.state;
    if (imageModalVisible) {
      return this.renderImageModal();
    } else {
      return (
        <div>
          <Button className="profile-image-button" onClick={this.showUploadModal}>
            {profileImage ? (
              <img
                id="profile-image"
                alt={I18n.t('screens.profile.edit.image.alt_description')}
                src={profileImage}
              />
            ) : (
              <DefaultProfileImage height="240" width="240" />
            )}
            <div id="change-profile-image">
              <span className="socrata-icon-edit" /> {I18n.t('screens.profile.edit.image.change_image')}
            </div>
          </Button>
        </div>
      );
    }
  }
}

export default ProfileImage;
