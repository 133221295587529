import React, { FunctionComponent, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { ErrorResponseCodeEnum, ErrorResponse } from '@socrata/core-email-verification-api';

import I18n from 'common/i18n';
import emailVerificationApi, { parseError } from 'common/core/email_verification';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import Form from 'common/components/Forms/Form';
import Input, { InputProps } from 'common/components/Forms/Input';
import { updateInputState, createBlankInputProps } from 'common/components/Forms/util';
import Button, { VARIANTS } from 'common/components/Button';
import Alert, { AlertType } from 'common/components/Alert';
import showConfirmationDialog from 'common/components/ConfirmationDialog';

import { getCurrentUserEmail } from '../../selectors.js';
import * as actions from '../../actions';
import validateEmailChangeInState from './validateEmailChangeInState';

interface ChangeEmailFormProps {
  /** Function to call to re-fetch the user's pending email change status */
  refetchEmailVerificationStatus: () => void;
}

/** Get the error code to display for a response from core */
const getMessageForErrorCode = (responseCode?: ErrorResponseCodeEnum): string => {
  switch (responseCode) {
    case ErrorResponseCodeEnum.USER_VERIFICATION_EMAIL_NOT_VALID:
      return I18n.t('account.common.core_errors.USER_VERIFICATION.EMAIL_NOT_VALID');
    case ErrorResponseCodeEnum.USER_VERIFICATION_PENDING_EMAIL_CHANGE_ALREADY_EXISTS:
      return I18n.t('account.common.core_errors.USER_VERIFICATION.PENDING_EMAIL_CHANGE_ALREADY_EXISTS');
    case ErrorResponseCodeEnum.AUTHENTICATION_SSO_MANAGED_ACCOUNT:
      return I18n.t('account.common.core_errors.AUTHENTICATION.SSO_MANAGED_ACCOUNT');
    case ErrorResponseCodeEnum.AUTHENTICATION_INCORRECT_PASSWORD:
      return I18n.t('account.common.core_errors.AUTHENTICATION.INCORRECT_PASSWORD');
    case ErrorResponseCodeEnum.AUTHENTICATION_LOCKED_OUT:
      return I18n.t('account.common.core_errors.AUTHENTICATION.LOCKED_OUT');
    default:
      return I18n.t('account.common.form.failed_updating_email');
  }
};

/**
 * Send a password change request to core.
 * If an error comes back, this will parse the error and throw it.
 *
 * @param newEmail Value from email input
 * @param confirmEmail Value from email confirm input
 * @param password Value from password input
 */
const makeChangeEmailRequest = async (newEmail: string, confirmEmail: string, password: string) => {
  try {
    await emailVerificationApi.changeEmail({
      changeEmailRequestBody: {
        newEmail,
        confirmEmail,
        password
      }
    });
  } catch (error) {
    // parse the error and throw it so react-query gives us the proper error response
    throw await parseError(error);
  }
};

/**
 * Form to enter a new email, confirm it, and enter your current password.
 *
 * This wil then hit core with the email verification API client to make the email change request.
 */
const ChangeEmailForm: FunctionComponent<ChangeEmailFormProps> = ({ refetchEmailVerificationStatus }) => {
  // email input
  const newEmailInputRef = useRef<HTMLInputElement>(null);
  const [newEmailInputState, setNewEmailInputState] = useState<InputProps>(
    createBlankInputProps({
      label: I18n.t('account.common.form.new_email'),
      name: 'newEmail',
      type: 'email',
      placeholder: I18n.t('account.common.form.email_prompt_new_email'),
      required: true
    })
  );

  // confirm email input
  const newEmailConfirmInputRef = useRef<HTMLInputElement>(null);
  const [newEmailConfirmInputState, setNewEmailConfirmInputState] = useState<InputProps>(
    createBlankInputProps({
      label: I18n.t('account.common.form.confirm_email'),
      name: 'confirmEmail',
      type: 'email',
      placeholder: I18n.t('account.common.form.email_prompt_confirm_email'),
      required: true
    })
  );

  // password input
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [currentPasswordInputState, setCurrentPasswordInputState] = useState<InputProps>(
    createBlankInputProps({
      label: I18n.t('account.common.form.current_password'),
      name: 'currentPassword',
      type: 'password',
      placeholder: I18n.t('account.common.form.email_prompt_current_password'),
      required: true,
      autoComplete: 'off'
    })
  );

  // redux stuff
  const currentEmail = useSelector(getCurrentUserEmail) as string; // selectors aren't typed yet :(

  const dispatch = useDispatch();
  const onCancelForm = () => dispatch(actions.closeEmailForm());
  const updateProfileEmail = (email: string) => dispatch(actions.updateEmail(email));

  // react-query to send out the email change
  const {
    isLoading: isLoadingEmailChange,
    isError: isEmailChangeError,
    error: emailChangeError,
    refetch: sendEmailChange
  } = useQuery(
    'send-email-change',
    async () =>
      await makeChangeEmailRequest(
        newEmailInputState.value!.toString(),
        newEmailConfirmInputState.value!.toString()!,
        currentPasswordInputState.value!.toString()!
      ),
    {
      // so that this is run by calling the `refetch` function instead of when the component mounts
      enabled: false,

      // don't retry if this fails, and don't cache the response
      retry: false,
      cacheTime: 0,

      onSuccess: () => {
        showToastNow({
          type: ToastType.SUCCESS,
          content: I18n.t('account.common.form.success_updating_information')
        });
        updateProfileEmail(newEmailInputState.value!.toString());
        onCancelForm();
      },

      onError: async (error: ErrorResponse) => {
        // This error is actually EXPECTED!
        // It means that email verification is turned on, and the user has to click a link
        // in an email that was sent to their NEW email address.
        // In this case, we just close the form and re-fetch the status.
        if (error.code === ErrorResponseCodeEnum.USER_EMAIL_CHANGE_PENDING) {
          onCancelForm();
          refetchEmailVerificationStatus();

          await showConfirmationDialog(
            <div>
              <p>{I18n.t('account.common.pending_email_change.received_email_change_request_1')}</p>
              <p>{I18n.t('account.common.pending_email_change.received_email_change_request_2')}</p>
            </div>,
            'pending-email-change-notice',
            {
              hideCancelButton: true,
              header: I18n.t('account.common.pending_email_change.please_verify_new_email')
            }
          );
        }
      }
    }
  );

  /**
   * Validate all of the current input values and update.
   *
   * @param validateRequired Whether or not to validate required inputs
   *    We only validate the required inputs when the form is submitted.
   */
  const validateAndUpdateInputs = (validateRequired = false) => {
    const updatedState = validateEmailChangeInState(
      {
        newEmail: newEmailInputState,
        newEmailConfirm: newEmailConfirmInputState,
        currentPassword: currentPasswordInputState
      },
      currentEmail,
      validateRequired
    );

    // only send the email change if the form is valid
    const { currentPassword, newEmail, newEmailConfirm } = updatedState;

    setNewEmailInputState(newEmail);
    setNewEmailConfirmInputState(newEmailConfirm);
    setCurrentPasswordInputState(currentPassword);
  };

  /**
   * Called when the form is submitted
   *
   * @param e Form submit event
   */
  const onEmailChangeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // trigger one last validation before trying to submit
    // "true" here validates that required fields are non-empty
    validateAndUpdateInputs(true);

    // we go through each input and, if it's invalid, focus on it
    // this makes it so that i.e. screen readers read out the error message
    if (!newEmailInputState.valid) {
      newEmailInputRef?.current?.focus();
    } else if (!newEmailConfirmInputState.valid) {
      newEmailConfirmInputRef?.current?.focus();
    } else if (!currentPasswordInputState.valid) {
      passwordInputRef?.current?.focus();
    } else {
      // if we get here, it means all the inputs are valid and we can submit the form
      sendEmailChange();
    }
  };

  return (
    <Form onSubmit={onEmailChangeSubmit} className="account-edit-wrapper">
      {isEmailChangeError && (
        <Alert type={AlertType.Error}>{getMessageForErrorCode(emailChangeError?.code)}</Alert>
      )}
      <Input
        {...newEmailInputState}
        ref={newEmailInputRef}
        onChange={(e) => updateInputState(e, newEmailInputState, setNewEmailInputState)}
        onBlur={() => validateAndUpdateInputs()}
        autoFocus
      />
      <Input
        {...newEmailConfirmInputState}
        ref={newEmailConfirmInputRef}
        onChange={(e) => updateInputState(e, newEmailConfirmInputState, setNewEmailConfirmInputState)}
        onBlur={() => validateAndUpdateInputs()}
      />
      <Input
        {...currentPasswordInputState}
        ref={passwordInputRef}
        onChange={(e) => updateInputState(e, currentPasswordInputState, setCurrentPasswordInputState)}
        onBlur={() => validateAndUpdateInputs()}
      />

      <div className="change-account-security-button-container">
        {/*
            Note: these buttons are defined here with "Submit" first and "Cancel" second
            so that tabbing through the form gets you to the "Submit" button first. This is to make
            interacting with the form easier with a screen reader.
            These are flipped display-wise in CSS via `flex-direction: row-reverse;`
          */}
        <Button
          title={I18n.t('account.common.form.submit_email_change')}
          type="submit"
          variant={VARIANTS.PRIMARY}
          busy={isLoadingEmailChange}
        >
          {I18n.t('account.common.form.submit')}
        </Button>
        <Button
          data-testid="cancel-change-email-form-button"
          title={I18n.t('account.common.form.cancel_email_change')}
          type="button"
          onClick={onCancelForm}
        >
          {I18n.t('account.common.form.cancel')}
        </Button>
      </div>
    </Form>
  );
};

export default ChangeEmailForm;
