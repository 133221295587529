import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import reducers from './reducer';
import apiKeysSagas from './components/DeveloperSettingsPane/ApiKeysTable/Redux/sagas';

/**
 * Create a redux store to use for profile edit
 *
 * @param config Base config to use in state
 */
const createProfileEditStore = (initialState) => {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'User Account Edit'
      })) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

  // combine all the sagas together
  function* sagas() {
    yield all([...apiKeysSagas]);
  }
  sagaMiddleware.run(sagas);

  return store;
};

export default createProfileEditStore;
