import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';

import { deleteKey } from './Redux/actions';
import { ApiKeyPropType } from './ApiKeysPropTypes';

/** Button placed at the end of each row of API key results */
class ApiKeysActionsDropdownButton extends Component {
  static propTypes = {
    keyId: PropTypes.string.isRequired,
    apiKey: ApiKeyPropType.isRequired,

    // redux actions
    doDeleteKey: PropTypes.func.isRequired
  };

  onDeleteKey = () => {
    const { keyId, doDeleteKey } = this.props;

    if (window.confirm(I18n.t('screens.profile.edit.api_keys.delete_confirm'))) {
      doDeleteKey(keyId);
    }
  };

  render() {
    const { keyId, apiKey } = this.props;
    const { deletedAt } = apiKey;

    // don't show any actions for deleted keys
    if (deletedAt) {
      return null;
    }

    return (
      <DropdownButton>
        {/* This has to be an array or DropdownButton's prop checks complain; once there are multiple items the array can be removed */}
        {[
          <DropdownItem key="delete" onClick={() => this.onDeleteKey(keyId)}>
            {I18n.t('screens.profile.edit.api_keys.delete')}
          </DropdownItem>
        ]}
      </DropdownButton>
    );
  }
}

const mapDispatchToProps = {
  doDeleteKey: (keyId) => deleteKey(keyId)
};

export default connect(null, mapDispatchToProps)(ApiKeysActionsDropdownButton);
