import React from 'react';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';

import AppTokensEditModal from './AppTokensEditModal';

export const AppTokenCreateButton = ({ busy, onClick, onSubmit, showModal, formErrors }) => {
  return (
    <div>
      <Button onClick={onClick} variant={VARIANTS.PRIMARY}>
        {I18n.t('screens.profile.edit.app_tokens.new_app_button')}
      </Button>
      {showModal && (
        <AppTokensEditModal
          busy={busy}
          title={I18n.t('screens.profile.edit.app_tokens.edit_app_token')}
          formErrors={formErrors}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default AppTokenCreateButton;
