import PropTypes from 'prop-types';

export const defaultUserConfig = {
  currentUser: {
    city: '',
    country: '',
    displayName: '',
    email: '',
    firstName: '',
    lastName: '',
    organization: '',
    title: ''
  }
};

export default PropTypes.shape({
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    description: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organization: PropTypes.string,
    screenName: PropTypes.string.isRequired,
    title: PropTypes.string
  })
});
