import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';

import AppTokensTable from './AppTokensTable';

import './developer-settings.scss';
import ApiKeysTable from './ApiKeysTable';

class DeveloperSettings extends Component {
  static propTypes = {
    userUid: PropTypes.string.isRequired
  };

  render() {
    const { userUid } = this.props;

    return (
      <div>
        <h1>{I18n.t('screens.profile.edit.developer_settings.title')}</h1>
        <div>
          <ApiKeysTable />
          <hr />
        </div>
        <AppTokensTable userUid={userUid} />
      </div>
    );
  }
}

export default DeveloperSettings;
