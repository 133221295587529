import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';

export function hasLocaleInExistingPath(locale: string): boolean {
  return startsWith(window.location.pathname, `/${locale}`);
}

export function generatePathWithLocale(path: string, locale: string): string {
  const replacement = hasLocaleInExistingPath(locale) ? `${locale}/` : '';
  return replace(path, '(:locale/)', replacement);
}
