import forEach from 'lodash/forEach';

import { PasswordRequirements } from '../../types';
import { MINIMUM_NEW_PASSWORD_REQUIREMENTS_TO_MEET } from '../../constants';

/**
 * @param  {string} value - The password to check for complexity requirements
 * @param  {rules} rules - collection of rules and meta data about the rules for newPasswordValidation to pass judgement
 *
 * @returns whether or not the given value satisfies the requirements
 */
const newPasswordValidation = (value = '', rules: PasswordRequirements): boolean => {
  let numberOfValidationsSatisfied = 0;
  forEach(rules, (rule) => {
    const satisfied = rule.validate(value);

    // required rule not met
    if (!satisfied && rule.mustMeet) {
      return false;
    }

    // met, increment our counter
    if (satisfied) {
      numberOfValidationsSatisfied += 1;
    }
  });

  return numberOfValidationsSatisfied >= MINIMUM_NEW_PASSWORD_REQUIREMENTS_TO_MEET;
};

export default newPasswordValidation;
