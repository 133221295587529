import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { propTypeEitherRequired } from 'common/propTypes';

import './tooltip.scss';

const eitherRenderOrComponent = propTypeEitherRequired({
  renderTooltip: PropTypes.func,
  tooltipComponent: PropTypes.object
});

/**
 * Renders a tooltip to the right on hover
 */
export default class Tooltip extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    renderTooltip: eitherRenderOrComponent,
    tooltipComponent: eitherRenderOrComponent,
    width: PropTypes.number
  };

  static defaultProps = {
    width: 300
  };

  render() {
    const { children, className, renderTooltip, tooltipComponent: TooltipContent, width } = this.props;
    const mergedClassName = cx(className, 'tooltip__anchor');
    return (
      <div className={mergedClassName} tabIndex={0}>
        <div className="tooltip__content" style={{ width }}>
          {renderTooltip ? renderTooltip() : <TooltipContent />}
        </div>
        {children}
      </div>
    );
  }
}
