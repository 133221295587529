import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import Checkbox from 'common/components/Forms/Checkbox';
import Input from 'common/components/Forms/Input';
import { invokeArgsMapValues } from 'common/functional_helpers';

import ApiKeyCreateAndCopyModals from './ApiKeyCreateAndCopyModals';
import {
  applySearch,
  searchInputChange,
  includeDeletedInputChange,
  clearCreatedKey,
  openCreateApiKeyModal,
  closeCreateApiKeyModal
} from './Redux/actions';
import {
  getCreatedKey,
  getSearchInputValue,
  getIncludeDeletedChecked,
  getIsApiKeyCreateModalOpen
} from './Redux/selectors';

class ApiKeysTableSearch extends Component {
  static propTypes = {
    // redux store
    createdKey: PropTypes.object,
    searchInputValue: PropTypes.string.isRequired,
    includeDeletedChecked: PropTypes.bool.isRequired,
    renderingCreateModal: PropTypes.bool.isRequired,

    // redux actions
    doApplySearch: PropTypes.func.isRequired,
    onSearchInputChange: PropTypes.func.isRequired,
    onIncludeDeletedInputChange: PropTypes.func.isRequired,
    onClearCreatedKey: PropTypes.func.isRequired,
    openCreateKeyModal: PropTypes.func.isRequired,
    closeCreateKeyModal: PropTypes.func.isRequired
  };

  onSearchSubmit = (e) => {
    const { doApplySearch } = this.props;

    e.preventDefault();
    doApplySearch();
  };

  onCreateModalClose = () => {
    const { onClearCreatedKey, closeCreateKeyModal } = this.props;

    this.setState({ renderingCreateModal: false });
    closeCreateKeyModal();
    onClearCreatedKey();
  };

  render() {
    const {
      searchInputValue,
      includeDeletedChecked,
      onSearchInputChange,
      onIncludeDeletedInputChange,
      createdKey,
      renderingCreateModal,
      openCreateKeyModal
    } = this.props;

    return (
      <div className="api-keys__top-bar">
        <Form onSubmit={this.onSearchSubmit} className="api-keys__search-form">
          <Input
            className="api-keys__search-form__input"
            onChange={onSearchInputChange}
            value={searchInputValue}
            placeholder="Name or ID"
            label={I18n.t('screens.profile.edit.api_keys.search')}
            name="search"
            iconName="search"
          />
          <Checkbox
            className="api-keys__search-form__include-deleted-checkbox"
            checked={includeDeletedChecked}
            onChange={onIncludeDeletedInputChange}
            label={I18n.t('screens.profile.edit.api_keys.include_deleted')}
            name="include_deleted"
          />
          <Button className="api-keys__search-form__submit-button" type="submit">
            {I18n.t('screens.profile.edit.api_keys.search')}
          </Button>
        </Form>
        <div>
          <Button type="button" variant="primary" onClick={() => openCreateKeyModal()}>
            {I18n.t('screens.profile.edit.api_keys.new_key_button')}
          </Button>
        </div>

        <ApiKeyCreateAndCopyModals
          startApiKeyCreation={renderingCreateModal}
          createdKey={createdKey}
          onCloseCreateKey={this.onCreateModalClose}
          onCloseSecretCopy={() => {}}
        />
      </div>
    );
  }
}

const mapStateToProps = invokeArgsMapValues({
  createdKey: getCreatedKey,
  searchInputValue: getSearchInputValue,
  includeDeletedChecked: getIncludeDeletedChecked,
  renderingCreateModal: getIsApiKeyCreateModalOpen
});

const mapDispatchToProps = {
  doApplySearch: applySearch,
  onSearchInputChange: (e) => searchInputChange(e.target.value),
  onIncludeDeletedInputChange: (e) => includeDeletedInputChange(e.target.checked),
  onClearCreatedKey: clearCreatedKey,
  closeCreateKeyModal: closeCreateApiKeyModal,
  openCreateKeyModal: openCreateApiKeyModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeysTableSearch);
