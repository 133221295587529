import isEmpty from 'lodash/isEmpty';

import { CORE_EMAIL_REGEX } from 'common/js_utils/accounts';
import I18n from 'common/i18n';
import { InputProps } from 'common/components/Forms/Input';

/** Represents the current state of the change email form */
export interface ChangeEmailFormState {
  currentPassword: InputProps;
  newEmail: InputProps;
  newEmailConfirm: InputProps;
}

/***
 * Performs all validation in an email change flow (that includes password)
 *  Checks whether email is a valid email (based on core regex logic)
 *  Ensures that new email and current are not the same
 *  Ensures new and confirm email match
 *  Ensures password is present
 *  Ensure all required inputs are filled in
 *
 * @param updatedValues Partial state that has only the updated values of
 * @param state Current state before the new value(e.target.value) is added
 * @param currentEmail Current email of the logged in user
 * @param validateRequired Whether or not to show an error for empty but required fields
 */
const validateEmailChangeInState = (
  state: ChangeEmailFormState,
  currentEmail: string,
  validateRequired = false
): ChangeEmailFormState => {
  // Reset validations and error messages
  const updatedState: ChangeEmailFormState = {
    ...state,
    currentPassword: {
      ...state.currentPassword,
      valid: true,
      errorMessage: undefined
    },
    newEmail: {
      ...state.newEmail,
      valid: true,
      errorMessage: undefined
    },
    newEmailConfirm: {
      ...state.newEmailConfirm,
      valid: true,
      errorMessage: undefined
    }
  };

  const email = state.newEmail.value as string | undefined;
  const password = state.currentPassword.value as string | undefined;
  const confirmEmail = state.newEmailConfirm.value as string | undefined;

  // validate that the new email is valid and not the current email
  const invalidEmail = !(!isEmpty(email) && RegExp(CORE_EMAIL_REGEX).test(email!.trim()));
  const newEmailIsCurrentEmail = email === currentEmail;
  if (invalidEmail) {
    updatedState.newEmail.valid = false;
    updatedState.newEmail.errorMessage = I18n.t('account.common.validation.invalid_email');
  } else if (newEmailIsCurrentEmail) {
    updatedState.newEmail.valid = false;
    updatedState.newEmail.errorMessage = I18n.t('account.common.validation.current_and_new_match_email');
  }

  // validate that the same email was entered twice
  const newEmailNotSameAsConfirmEmail = !!email && !!confirmEmail && email !== confirmEmail;
  if (newEmailNotSameAsConfirmEmail) {
    updatedState.newEmailConfirm.valid = false;
    updatedState.newEmailConfirm.errorMessage = I18n.t('account.common.validation.email_mismatch');
  }

  // validate that all required inputs are valid
  if (validateRequired) {
    if (isEmpty(email)) {
      updatedState.newEmail.valid = false;
      updatedState.newEmail.errorMessage = I18n.t('core.validation.enter_a_value');
    }

    if (isEmpty(confirmEmail)) {
      updatedState.newEmailConfirm.valid = false;
      updatedState.newEmailConfirm.errorMessage = I18n.t('core.validation.enter_a_value');
    }

    if (isEmpty(password)) {
      updatedState.currentPassword.valid = false;
      updatedState.currentPassword.errorMessage = I18n.t('core.validation.enter_a_value');
    }
  }

  return updatedState;
};

export default validateEmailChangeInState;
