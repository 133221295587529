import React, { FunctionComponent } from 'react';

import ApiKeyCreateModal from './ApiKeyCreateModal';
import ApiKeySecretCopyModal from './ApiKeySecretCopyModal';

import type { ApiKey } from '@socrata/core-api-keys-api';

interface Props {
  startApiKeyCreation: boolean;
  createdKey?: ApiKey;
  onOpenSecretModal: () => void;
  onCloseCreateKey: () => void;
  onCloseSecretCopy: () => void;
}

/**
 * The Api Key creation flow involves pressing "Create new API Key" in the `ApiKeysTableSearch` component
 *  Once a valid name is supplied and the key is created the modal closes and a `ApiKeySecretCopy` modal is opened
 */
const CreateAndCopyModals: FunctionComponent<Props> = ({
  startApiKeyCreation,
  createdKey,
  onCloseCreateKey,
  onCloseSecretCopy
}) => {
  /**
   * Convenient wrapper that ensures all modes are closed once a key is copied
   */
  const closeSecretCopyModal = () => {
    onCloseCreateKey();
    onCloseSecretCopy();
  };
  // If key is created we need to close the first modal and open the next modal
  if (createdKey) {
    return <ApiKeySecretCopyModal onClose={closeSecretCopyModal} apiKey={createdKey} />;
  } else if (startApiKeyCreation) {
    return <ApiKeyCreateModal onClose={onCloseCreateKey} />;
  }
  return null;
};

export default CreateAndCopyModals;
