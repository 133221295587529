import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import { fetchJsonWithParsedError } from 'common/http';
import Form from 'common/components/Forms/Form';
import Input, { InputProps } from 'common/components/Forms/Input';
import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import PasswordHintModal from 'common/components/PasswordHintModal';
import { defaultHeaders } from 'common/http';

import generatePasswordRequirements from './generatePasswordRequirements';
import validatePasswordChangeInState from './validatePasswordChangeInState';
import * as actions from '../../actions';
import { getPasswordRequirements } from '../../selectors.js';
import CoreError from 'common/types/coreError';

/** Props from redux state */
interface StateProps {
  /**
   * This comes from rails and is stored in the redux state
   * These values come from the site config.
   */
  passwordRequirements: {
    hasLength: {
      min: number;
      max: number;
    };
  };
}

/** Props to dispatch redux actions */
interface DispatchProps {
  /** Called to close the form */
  closePasswordForm: () => void;

  /** Called to notify that the password has been changed or not */
  passwordChanged: (changed: boolean) => void;
}

type Props = StateProps & DispatchProps;

/** Component state */
export interface State {
  /** Whether or not we are currently showing the password hint modal */
  renderPasswordHintModal: boolean;

  /** Current password input state */
  currentPassword: InputProps;

  /** New password input state */
  newPassword: InputProps;

  /** Password confirm input state */
  newPasswordConfirm: InputProps;
}

/** Form to change the current user's password, including complexity validation */
export class ChangePasswordForm extends Component<Props, State> {
  currentPasswordRef = React.createRef<HTMLInputElement>();
  newPasswordRef = React.createRef<HTMLInputElement>();
  newPasswordConfirmRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    // grab the password requirements from the redux statue
    // (these actually come from site config via rails injected into this component when the store is created)
    const {
      hasLength: { min, max }
    } = props.passwordRequirements;

    this.state = {
      renderPasswordHintModal: false,
      currentPassword: {
        label: I18n.t('account.common.form.current_password'),
        name: 'oldPassword',
        type: 'password',
        autoComplete: 'off',
        placeholder: I18n.t('account.common.form.email_prompt_current_password'),
        required: true,
        autoFocus: true,
        value: ''
      },
      newPassword: {
        label: I18n.t('account.common.form.new_password'),
        name: 'newPassword',
        type: 'password',
        autoComplete: 'off',
        placeholder: I18n.t('account.common.form.enter_new_password'),
        required: true,
        value: ''
      },
      newPasswordConfirm: {
        label: I18n.t('account.common.form.confirm_password'),
        name: 'confirmPassword',
        type: 'password',
        autoComplete: 'off',
        placeholder: I18n.t('account.common.form.enter_new_password_confirm'),
        required: true,
        value: ''
      }
    };
  }

  /** Typing into the current password field */
  onPasswordInput = (e: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({
      currentPassword: {
        ...this.state.currentPassword,
        value: e.target.value,
        valid: true,
        errorMessage: undefined
      }
    });

  /** Typing into the new password field */
  onNewPasswordInput = (e: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({
      newPassword: {
        ...this.state.newPassword,
        value: e.target.value,
        valid: true,
        errorMessage: undefined
      }
    });

  /** Typing into the current password field */
  onConfirmPasswordInput = (e: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({
      newPasswordConfirm: {
        ...this.state.newPasswordConfirm,
        value: e.target.value,
        valid: true,
        errorMessage: undefined
      }
    });

  /** Show a toast letting the user know their password has been changed */
  static passwordUpdatedSuccessfullyToast(): void {
    showToastNow({
      type: ToastType.SUCCESS,
      content: I18n.t('account.common.form.password_updated')
    });
  }

  static getTranslatedError = (errorJson?: CoreError): string => {
    if (!errorJson || !errorJson.code) {
      return I18n.t('account.common.form.password_failed_update');
    } else {
      return I18n.t(`account.common.core_errors.${errorJson.code}`, {
        // fallback in case we don't have a translation for the error
        defaultValue: I18n.t('account.common.form.password_failed_update')
      });
    }
  };

  /** Show a toast letting the user know there was an error changing their password */
  static passwordUpdateFailedToast(errorJson?: CoreError): void {
    showToastNow({
      type: ToastType.ERROR,
      content: this.getTranslatedError(errorJson)
    });
  }

  /** Submit the form to core to change the user's password */
  sendPasswordChange = async (): Promise<void> => {
    const { closePasswordForm, passwordChanged } = this.props;

    const oldPassword = this.state.currentPassword.value;
    const newPassword = this.state.newPassword.value;
    const confirmPassword = this.state.newPasswordConfirm.value;

    try {
      const response = await fetchJsonWithParsedError('/api/users/current/password', {
        headers: defaultHeaders,
        method: 'POST',
        body: JSON.stringify({ oldPassword, newPassword, confirmPassword })
      });

      if (response) {
        ChangePasswordForm.passwordUpdatedSuccessfullyToast();
      }

      closePasswordForm();
      passwordChanged(true);
    } catch (e) {
      if (e.json) {
        ChangePasswordForm.passwordUpdateFailedToast(e.json);
      } else {
        ChangePasswordForm.passwordUpdateFailedToast();
      }
    }
  };

  /** Form submitted */
  onPasswordChangeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { passwordRequirements } = this.props;

    e.preventDefault();

    const updatedState = validatePasswordChangeInState(
      this.state,
      generatePasswordRequirements(passwordRequirements.hasLength.min, passwordRequirements.hasLength.max),
      true // validate required
    );
    this.setState(updatedState);

    const { currentPassword, newPassword, newPasswordConfirm } = updatedState;

    // we go through each input and, if it's invalid, focus on it
    // this makes it so that i.e. screen readers read out the error message
    if (!currentPassword.valid) {
      this.currentPasswordRef.current?.focus();
    } else if (!newPassword.valid) {
      this.newPasswordRef.current?.focus();
    } else if (!newPasswordConfirm.valid) {
      this.newPasswordConfirmRef.current?.focus();
    } else {
      this.sendPasswordChange();
    }
  };

  /** Change password cancelled; just close the form */
  onCancelForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const { closePasswordForm } = this.props;

    e.preventDefault();

    closePasswordForm();
  };

  /** Toggle showing the password hint modal */
  onTogglePasswordHintModal = (): void => {
    const { renderPasswordHintModal } = this.state;
    this.setState({ renderPasswordHintModal: !renderPasswordHintModal });
  };

  render(): ReactElement {
    const { currentPassword, newPassword, newPasswordConfirm, renderPasswordHintModal } = this.state;
    const {
      passwordRequirements: { hasLength }
    } = this.props;

    const validateFormOnBlur = () =>
      this.setState(
        validatePasswordChangeInState(this.state, generatePasswordRequirements(hasLength.min, hasLength.max))
      );

    return (
      <Form onSubmit={this.onPasswordChangeSubmit} className="account-edit-wrapper" autoComplete="off">
        <Input
          {...currentPassword}
          onChange={this.onPasswordInput}
          onBlur={validateFormOnBlur}
          ref={this.currentPasswordRef}
        />
        <Input
          {...newPassword}
          onChange={this.onNewPasswordInput}
          onBlur={validateFormOnBlur}
          ref={this.newPasswordRef}
        >
          <button className="password-hint-button" type="button" onClick={this.onTogglePasswordHintModal}>
            {I18n.t('account.common.form.password_restrictions')}
            <SocrataIcon name={IconName.Info} />
          </button>
        </Input>
        <Input
          {...newPasswordConfirm}
          onChange={this.onConfirmPasswordInput}
          onBlur={validateFormOnBlur}
          ref={this.newPasswordConfirmRef}
        />
        <div className="change-account-security-button-container">
          {/*
            Note: these buttons are defined here with "Submit" first and "Cancel" second
            so that tabbing through the form gets you to the "Submit" button first. This is to make
            interacting with the form easier with a screen reader.
            These are flipped display-wise in CSS via `flex-direction: row-reverse;`
          */}
          <Button
            type="submit"
            variant={VARIANTS.PRIMARY}
            title={I18n.t('account.common.form.submit_password_change')}
          >
            {I18n.t('account.common.form.submit')}
          </Button>
          <Button
            type="button"
            onClick={this.onCancelForm}
            title={I18n.t('account.common.form.cancel_password_change')}
          >
            {I18n.t('account.common.form.cancel')}
          </Button>
        </div>
        {renderPasswordHintModal && (
          <PasswordHintModal
            onDismiss={this.onTogglePasswordHintModal}
            passwordRequirements={{
              minLength: hasLength.min,
              maxLength: hasLength.max
            }}
          />
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state: State): StateProps => ({
  passwordRequirements: getPasswordRequirements(state)
});

const mapDispatchToProps = {
  closePasswordForm: actions.closePasswordForm,
  passwordChanged: actions.passwordChanged
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
