import React, { Component } from 'react';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import I18n from 'common/i18n';
import Button, { VARIANTS, SIZES } from 'common/components/Button';
import Input from 'common/components/Forms/Input';
import Form from 'common/components/Forms/Form';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/AccessibleModal';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';

import { clearCreatedKey } from './Redux/actions.js';
import type { ApiKey } from '@socrata/core-api-keys-api';

import Tooltip from '../../Tooltip.js';

interface OwnProps {
  apiKey: ApiKey;
  onClose: () => void;
}

interface DispatchProps {
  /** redux dispatch */
  onClearCreatedKey: () => void;
}

type Props = DispatchProps & OwnProps;

/**
 * This modal shows a newly created API key, including its secret.
 * The modal cannot be closed until the secret is copied.
 */
export class ApiKeySecretCopyModal extends Component<Props> {
  state = {
    secretCopied: false,
    idCopied: false
  };

  onSecretCopied = () => {
    // set secretCopied to true so the user can close the modal
    this.setState({ secretCopied: true });

    // let the user know it was copied
    showSuccessToastNow(I18n.t('shared.components.click_to_copy_code_block.copied_to_clipboard'));
  };

  onIdCopied = () => {
    // set secretCopied to true so the user can close the modal
    this.setState({ idCopied: true });

    // let the user know it was copied
    showSuccessToastNow(I18n.t('shared.components.click_to_copy_code_block.copied_to_clipboard'));
  };

  onCloseModal = () => {
    const { onClearCreatedKey, onClose } = this.props;

    // tell redux store that we're done with the created key
    onClearCreatedKey();

    // actually close the modal
    onClose();
  };

  renderSecret = (secretCopied: boolean, keySecret: string) => (
    <div className="api-keys__secret-modal__secret-container">
      <Input
        readOnly
        label={I18n.t('screens.profile.edit.api_keys.secret_copy_modal.key_secret_input_label')}
        name="key_secret"
        value={keySecret}
        onCopy={() => this.setState({ secretCopied: true })}
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
      />

      <div className="api-keys__secret-modal__secret-copy-container">
        <CopyToClipboard text={keySecret} onCopy={this.onSecretCopied}>
          <Button size={SIZES.SMALL} variant={VARIANTS.TRANSPARENT} className="secret-copy-button">
            {secretCopied ? (
              <SocrataIcon name={IconName.CheckmarkAlt} />
            ) : (
              <SocrataIcon name={IconName.CopyDocument} />
            )}
            {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.click_to_copy_secret')}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );

  renderId = (idCopied: boolean, keyId: string) => (
    <div className="api-keys__secret-modal__secret-container">
      <Input
        readOnly
        label={I18n.t('screens.profile.edit.api_keys.secret_copy_modal.key_id_input_label')}
        name="key_id"
        value={keyId}
      />

      <div className="api-keys__secret-modal__secret-copy-container">
        <CopyToClipboard text={keyId} onCopy={this.onIdCopied}>
          <Button size={SIZES.SMALL} variant={VARIANTS.TRANSPARENT}>
            {idCopied ? (
              <SocrataIcon name={IconName.CheckmarkAlt} />
            ) : (
              <SocrataIcon name={IconName.CopyDocument} />
            )}
            {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.click_to_copy_id')}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );

  renderCopyBeforeCloseMessage = (secretCopied: boolean) =>
    !secretCopied ? (
      <div className="alert warning">
        {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.copy_secret_before_closing')}
      </div>
    ) : null;

  renderCloseButton = (secretCopied: boolean) =>
    // if the secret has been copied, the button will close the modal
    // otherwise, the button is disabled and has a tooltip
    secretCopied ? (
      <Button className="close-button" onClick={this.onCloseModal}>
        {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.close')}
      </Button>
    ) : (
      <Button className="close-button-disabled" disabled>
        {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.close')}
      </Button>
    );

  render() {
    const { secretCopied, idCopied } = this.state;
    const { apiKey } = this.props;
    const { keyName, keyId, keySecret } = apiKey;

    return (
      <Modal
        onDismiss={this.onCloseModal}
        contentLabel={I18n.t('screens.profile.edit.api_keys.secret_copy_modal.new_key_modal_title', {
          keyName: keyName
        })}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <ModalHeader showCloseButton={false}>
          {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.new_key_modal_title', {
            keyName: keyName
          })}
        </ModalHeader>
        <ModalContent>
          <div>{I18n.t('screens.profile.edit.api_keys.secret_copy_modal.next')}</div>
          <Form>
            {/** API Key ID */}
            {this.renderId(idCopied, keyId!)}

            {/** Alert box to copy API */}
            <div className="alert warning">
              <strong> {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.important')} </strong>
              {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.copy_reminder')}
              <div>
                {I18n.t('screens.profile.edit.api_keys.secret_copy_modal.copy_secret_before_closing')}
              </div>
            </div>

            {/** Api Key Secret */}
            {this.renderSecret(secretCopied, keySecret!)}

            {this.renderCopyBeforeCloseMessage(secretCopied)}
          </Form>
        </ModalContent>

        <ModalFooter>{this.renderCloseButton(secretCopied)}</ModalFooter>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  onClearCreatedKey: clearCreatedKey
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(ApiKeySecretCopyModal);
