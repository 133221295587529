import {
  OPEN_PASSWORD_FORM,
  CLOSE_PASSWORD_FORM,
  OPEN_EMAIL_FORM,
  CLOSE_EMAIL_FORM,
  UPDATE_USERS_EMAIL,
  PASSWORD_CHANGED
} from './constants';

export const openPasswordForm = () => ({
  type: OPEN_PASSWORD_FORM
});

export const closePasswordForm = () => ({
  type: CLOSE_PASSWORD_FORM
});

export const passwordChanged = (changed: boolean) => ({
  type: PASSWORD_CHANGED,
  changed
});

export const openEmailForm = () => ({
  type: OPEN_EMAIL_FORM
});

export const closeEmailForm = () => ({
  type: CLOSE_EMAIL_FORM
});

export const updateEmail = (updatedEmail: string) => ({
  type: UPDATE_USERS_EMAIL,
  email: updatedEmail
});
