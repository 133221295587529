import { combineReducers } from 'redux';
import merge from 'lodash/merge';
import apiKeys from './components/DeveloperSettingsPane/ApiKeysTable/Redux/reducer';
import {
  OPEN_PASSWORD_FORM,
  OPEN_EMAIL_FORM,
  CLOSE_PASSWORD_FORM,
  CLOSE_EMAIL_FORM,
  UPDATE_USERS_EMAIL,
  UPDATE_USER_STATE,
  PASSWORD_CHANGED
} from './constants';

const configInitialState = {
  currentUser: {},
  passwordRequirements: {},
  routes: {}
};

const formInitialState = {
  openPasswordChangeForm: false,
  openEmailChangeForm: false,
  passwordChanged: false
};

const config = (state = configInitialState, action) => {
  switch (action.type) {
    case UPDATE_USERS_EMAIL:
      return merge({}, state, { currentUser: { email: action.email } });
    case UPDATE_USER_STATE:
      return merge({}, state, { currentUser: { ...action.newState } });
    default:
      return state;
  }
};

const form = (state = formInitialState, action) => {
  switch (action.type) {
    case OPEN_PASSWORD_FORM:
      return { ...state, openPasswordChangeForm: true };
    case OPEN_EMAIL_FORM:
      return { ...state, openEmailChangeForm: true };
    case CLOSE_PASSWORD_FORM:
      return { ...state, openPasswordChangeForm: false };
    case CLOSE_EMAIL_FORM:
      return { ...state, openEmailChangeForm: false };
    case PASSWORD_CHANGED:
      return { ...state, passwordChanged: action.changed };
    default:
      return state;
  }
};

const reducers = combineReducers({ config, form, apiKeys });
export default reducers;
