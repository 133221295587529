// tells the saga to fetch the current page with whatever currentSearchParams are
export const API_KEYS_FETCH_CURRENT_PAGE = 'API_KEYS_FETCH_CURRENT_PAGE';
export const fetchCurrentPage = () => ({ type: API_KEYS_FETCH_CURRENT_PAGE });

// sagas reporting a successful page fetch
export const API_KEYS_FETCH_CURRENT_PAGE_SUCCESS = 'API_KEYS_FETCH_CURRENT_PAGE_SUCCESS';
export const fetchCurrentPageSuccess = (currentPage, hasNextPage) => ({
  type: API_KEYS_FETCH_CURRENT_PAGE_SUCCESS,
  currentPage,
  hasNextPage
});

// sagas reporting a failed page fetch
export const API_KEYS_FETCH_CURRENT_PAGE_FAIL = 'API_KEYS_FETCH_CURRENT_PAGE_FAIL';
export const fetchCurrentPageFail = (error) => ({ type: API_KEYS_FETCH_CURRENT_PAGE_FAIL, error });

// go to the next page; note that this only updated currentSearchParams and doesn't actually fetch the next page
// (dispatch API_KEYS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const API_KEYS_GO_TO_NEXT_PAGE = 'API_KEYS_GO_TO_NEXT_PAGE';
export const goToNextPage = () => ({ type: API_KEYS_GO_TO_NEXT_PAGE });

// go to the next page; note that this only updated currentSearchParams and doesn't actually fetch the next page
// (dispatch API_KEYS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const API_KEYS_GO_TO_PREVIOUS_PAGE = 'API_KEYS_GO_TO_PREVIOUS_PAGE';
export const goToPreviousPage = () => ({ type: API_KEYS_GO_TO_PREVIOUS_PAGE });

// changes the column that we're sorting by in currentSearchParams
// (dispatch API_KEYS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const API_KEYS_CHANGE_SORT_COLUMN = 'API_KEYS_CHANGE_SORT_COLUMN';
export const changeSortColumn = (columnName) => ({ type: API_KEYS_CHANGE_SORT_COLUMN, columnName });

// apply the current search input and include deleted checkbox to the currentSearchParams
// (dispatch API_KEYS_FETCH_CURRENT_PAGE to trigger a page fetch)
export const API_KEYS_APPLY_SEARCH = 'API_KEYS_APPLY_SEARCH';
export const applySearch = () => ({ type: API_KEYS_APPLY_SEARCH });

// search input changing
export const API_KEYS_SEARCH_INPUT_CHANGE = 'API_KEYS_SEARCH_INPUT_CHANGE';
export const searchInputChange = (value) => ({ type: API_KEYS_SEARCH_INPUT_CHANGE, value });

// include deleted checked changing
export const API_KEYS_INCLUDE_DELETED_INPUT_CHANGE = 'API_KEYS_INCLUDE_DELETED_INPUT_CHANGE';
export const includeDeletedInputChange = (checked) => ({
  type: API_KEYS_INCLUDE_DELETED_INPUT_CHANGE,
  checked
});

// tell sagas to create a new key
export const API_KEYS_CREATE_KEY = 'API_KEYS_CREATE_KEY';
export const createKey = (keyName) => ({
  type: API_KEYS_CREATE_KEY,
  keyName
});

// creating a key was successful; returned key will contain the secret
export const API_KEYS_CREATE_KEY_SUCCESS = 'API_KEYS_CREATE_KEY_SUCCESS';
export const createKeySuccess = (apiKey) => ({
  type: API_KEYS_CREATE_KEY_SUCCESS,
  apiKey
});

// creating a key failed
export const API_KEYS_CREATE_KEY_FAIL = 'API_KEYS_CREATE_KEY_FAIL';
export const createKeyFail = (error) => ({
  type: API_KEYS_CREATE_KEY_FAIL,
  error
});

// clear out the newly created key that we have
export const API_KEYS_CLEAR_CREATED_KEY = 'API_KEYS_CLEAR_CREATED_KEY';
export const clearCreatedKey = () => ({ type: API_KEYS_CLEAR_CREATED_KEY });

// tells the sagas to delete a key
export const API_KEYS_DELETE_KEY = 'API_KEYS_DELETE_KEY';
export const deleteKey = (keyId) => ({
  type: API_KEYS_DELETE_KEY,
  keyId
});

// sagas reporting key delete success
export const API_KEYS_DELETE_KEY_SUCCESS = 'API_KEYS_DELETE_KEY_SUCCESS';
export const deleteKeySuccess = (deletedKey) => ({
  type: API_KEYS_DELETE_KEY_SUCCESS,
  deletedKey
});

// sagas reporting key delete failure
export const API_KEYS_DELETE_KEY_FAIL = 'API_KEYS_DELETE_KEY_FAIL';
export const deleteKeyFail = (error) => ({
  type: API_KEYS_DELETE_KEY_FAIL,
  error
});

// Action to update the state via the reducer (checks if the modal is to be rendered)
export const API_KEY_CREATE_MODAL_OPEN = 'API_KEY_CREATE_MODAL_OPEN';
export const openCreateApiKeyModal = () => ({
  type: API_KEY_CREATE_MODAL_OPEN
});

// Action to update the state via the reducer (checks if the modal is to be unmounted)
export const API_KEY_CREATE_MODAL_CLOSE = 'API_KEY_CREATE_MODAL_CLOSE';
export const closeCreateApiKeyModal = () => ({
  type: API_KEY_CREATE_MODAL_CLOSE
});
