import { SORT_DIRECTION } from 'common/Constants';
import I18n from 'common/i18n';
import {
  showErrorToastNow,
  showSuccessToastOnPageReload
} from 'common/components/ToastNotification/Toastmaster';
import { reload as windowLocationReload } from 'common/window_location';

import { PAGE_SIZE, CREATED_AT_COLUMN_NAME, DELETED_AT_COLUMN_NAME } from '../ApiKeysConstants';
import * as actions from './actions';

export const defaultState = {
  // search params directly sent to /api/api_keys
  currentSearchParams: {
    // we fetch an extra one to see if there's another page
    limit: PAGE_SIZE + 1,
    sortBy: CREATED_AT_COLUMN_NAME,
    sortOrder: SORT_DIRECTION.DESC,
    seek: null,
    nameSearch: null,
    includeDeleted: false
  },

  // current list of api keys
  currentPage: null,

  // previous pages that were seeked to
  // essentially a stack to enable going to previous pages
  previousSeeks: [],

  // when we fetch results, we fetch one more than we plan on showing...
  // if we get the extra, we know there's at least another page
  hasNextPage: false,

  // current value of search input; applied to currentSearchParams when "Search" button is clicked
  searchInputValue: '',

  // current value of include deleted checkbox; applied to currentSearchParams when "Search" button is clicked
  includeDeletedChecked: false,

  // any current error being displayed
  error: null,

  modals: {
    openCreateApiKeyModal: false
  }
};

const fetchCurrentPageSuccess = (state, { currentPage, hasNextPage }) => ({
  ...state,
  currentPage,
  hasNextPage
});

const fetchCurrentPageFail = (state, { error }) => {
  console.error('Error fetching API keys', error, error.json);

  // clear out the state
  return {
    ...state,
    currentPage: [],
    previousSeeks: [],
    hasNextPage: false,
    searchInputValue: '',
    includeDeletedChecked: false,
    error
  };
};

const goToNextPage = (state) => {
  const { currentPage, currentSearchParams, previousSeeks } = state;

  // we want to grab the value of the currently sorted field
  const seek = currentPage[currentPage.length - 1].keyId;

  return {
    ...state,
    previousSeeks: [currentSearchParams.seek, ...previousSeeks],
    currentSearchParams: {
      ...currentSearchParams,
      seek
    }
  };
};

const goToPreviousPage = (state) => {
  const { currentSearchParams, previousSeeks } = state;

  // basically, pop the last seek off the stack
  const [seek, ...leftoverPreviousSeeks] = previousSeeks;

  return {
    ...state,
    previousSeeks: leftoverPreviousSeeks,
    currentSearchParams: {
      ...currentSearchParams,
      seek
    }
  };
};

const changeSortColumn = (state, { columnName }) => {
  const { currentSearchParams } = state;

  const { sortBy, sortOrder } = currentSearchParams;

  if (columnName === sortBy) {
    // if we're re-selecting the column we're sorting by, reverse direction
    return {
      ...state,
      currentSearchParams: {
        ...currentSearchParams,
        sortOrder: sortOrder === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC,
        seek: null
      },
      previousSeeks: []
    };
  } else {
    // otherwise, we're selecting a new column
    return {
      ...state,
      currentSearchParams: {
        ...currentSearchParams,
        sortBy: columnName,
        sortOrder: SORT_DIRECTION.DESC,
        seek: null
      },
      previousSeeks: []
    };
  }
};

const searchInputChange = (state, { value }) => ({
  ...state,
  searchInputValue: value
});

const includeDeletedInputChange = (state, { checked }) => ({
  ...state,
  includeDeletedChecked: checked
});

const applySearch = (state) => {
  const { currentSearchParams, searchInputValue, includeDeletedChecked } = state;
  const { sortBy, sortOrder } = currentSearchParams;

  // if we were sorting by deleted at but aren't showing deleted keys anymore,
  // we change back to the default sort column/order
  const wasSortingByDeletedAt = includeDeletedChecked == false && sortBy == DELETED_AT_COLUMN_NAME;

  // changing the state automatically triggers a page fetch if it's different
  return {
    ...state,
    currentSearchParams: {
      ...currentSearchParams,
      includeDeleted: includeDeletedChecked,
      nameSearch: searchInputValue,

      sortBy: wasSortingByDeletedAt ? defaultState.currentSearchParams.sortBy : sortBy,
      sortOrder: wasSortingByDeletedAt ? defaultState.currentSearchParams.sortOrder : sortOrder,

      // back to page 0
      seek: null
    },
    previousSeeks: []
  };
};

// NOTE: actual key creation is done in sagas
const createKey = (state) => ({
  ...state,
  createKeyError: null
});

const createKeySuccess = (state, { apiKey }) => ({
  ...state,
  createdKey: apiKey,
  createKeyError: null
});

const createKeyFail = (state, { error }) => {
  console.error('Error creating API key', error, error.json);

  return {
    ...state,
    createdKey: null,
    createKeyError: error
  };
};

const clearCreatedKey = (state) => ({
  ...state,
  createdKey: null,
  createKeyError: null
});

const deleteKeySuccess = (state, { deletedKey: { keyName } }) => {
  // reload the page to reflect the deleted key
  showSuccessToastOnPageReload(I18n.t('screens.profile.edit.api_keys.delete_success', { keyName }));
  windowLocationReload();

  return state;
};

const deleteKeyFail = (state, { error }) => {
  console.error('Error deleting API key', error);
  showErrorToastNow(I18n.t('screens.profile.edit.api_keys.delete_error', { message: error }));

  return state;
};

const openApiKeyCreateModal = (state) => ({
  ...state,
  modals: {
    openCreateApiKeyModal: true
  }
});

const closeApiKeyCreateModal = (state) => ({
  ...state,
  modals: {
    openCreateApiKeyModal: false
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    // fetch current page
    case actions.API_KEYS_FETCH_CURRENT_PAGE_SUCCESS:
      return fetchCurrentPageSuccess(state, action);
    case actions.API_KEYS_FETCH_CURRENT_PAGE_FAIL:
      return fetchCurrentPageFail(state, action);

    // prev/next page
    case actions.API_KEYS_GO_TO_NEXT_PAGE:
      return goToNextPage(state, action);
    case actions.API_KEYS_GO_TO_PREVIOUS_PAGE:
      return goToPreviousPage(state, action);

    // change sort order
    case actions.API_KEYS_CHANGE_SORT_COLUMN:
      return changeSortColumn(state, action);

    // searching
    case actions.API_KEYS_SEARCH_INPUT_CHANGE:
      return searchInputChange(state, action);
    case actions.API_KEYS_INCLUDE_DELETED_INPUT_CHANGE:
      return includeDeletedInputChange(state, action);
    case actions.API_KEYS_APPLY_SEARCH:
      return applySearch(state, action);

    // create key
    case actions.API_KEYS_CREATE_KEY:
      return createKey(state, action);
    case actions.API_KEYS_CREATE_KEY_SUCCESS:
      return createKeySuccess(state, action);
    case actions.API_KEYS_CREATE_KEY_FAIL:
      return createKeyFail(state, action);
    case actions.API_KEYS_CLEAR_CREATED_KEY:
      return clearCreatedKey(state, action);

    // delete key
    case actions.API_KEYS_DELETE_KEY_SUCCESS:
      return deleteKeySuccess(state, action);
    case actions.API_KEYS_DELETE_KEY_FAIL:
      return deleteKeyFail(state, action);

    // open create API key modal
    case actions.API_KEY_CREATE_MODAL_OPEN:
      return openApiKeyCreateModal(state);

    // close create API key modal
    case actions.API_KEY_CREATE_MODAL_CLOSE:
      return closeApiKeyCreateModal(state);

    default:
      return state;
  }
};
