import React from 'react';

import I18n from 'common/i18n';
import ClickToCopyCodeBlock from 'common/components/ClickToCopyCodeBlock';
import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';

import ClickToView from './ClickToView';
import { fetchAppTokenSecret } from './AppTokenUtils';

export const SecretToken = ({ id, userUid, label }) => {
  const onClick = (setValue) => {
    fetchAppTokenSecret(userUid, id).then(
      (secretToken) => setValue(secretToken),
      (error) => {
        showErrorToastNow(I18n.t('screens.profile.app_tokens.show_secret_token_error'));
        console.error(error);
      }
    );
  };
  const renderValue = (value) => <ClickToCopyCodeBlock value={value} />;
  return (
    <ClickToView onClick={onClick} renderValue={renderValue}>
      {label}
    </ClickToView>
  );
};

export default SecretToken;
