import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';

import { EmailVerificationStatus } from '@socrata/core-email-verification-api';

import I18n from 'common/i18n';
import Spinner from 'common/components/Spinner';
import Alert, { AlertType } from 'common/components/Alert';

import fetchEmailVerificationStatus, { queryConfig } from './fetchEmailVerificationStatusUtil';
import ChangeEmailForm from './ChangeEmailForm';
import PendingEmailChangeInfo from './PendingEmailChangeInfo';

export interface ChangeEmailFormWrapperProps {
  /** The user's current email address */
  emailAddress: string;

  /** Wether or not the change email form is currently open */
  emailFormOpen: boolean;

  /**
   * Whether or not the user is an "SSO" account.
   * If this is true, then the user is unable to change their email.
   */
  userIsSsoOnly: boolean;

  /** Opens the email change form */
  handleEmailChange: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const ChangeEmailFormWrapper: FunctionComponent<ChangeEmailFormWrapperProps> = ({
  emailAddress,
  emailFormOpen,
  userIsSsoOnly,
  handleEmailChange
}) => {
  const {
    isLoading: busyFetchingCurrentStatus,
    isError: isFetchingStatusError,
    data: currentStatus,
    refetch: recheckStatus
  } = useQuery('check-email-status', async () => await fetchEmailVerificationStatus(), queryConfig);

  const hasPendingEmailChange = currentStatus?.status === EmailVerificationStatus.pending_email_change;

  const shouldShowChangeEmailLink =
    // if it's open, then the link has already been clicked
    !emailFormOpen &&
    // SSO-only users can't change their email
    !userIsSsoOnly &&
    // loading...
    !busyFetchingCurrentStatus &&
    // if you have a pending email change, you have to cancel it first
    !hasPendingEmailChange;

  // failed to fetch current status; don't let them try to change their email
  if (isFetchingStatusError) {
    return (
      <Alert type={AlertType.Error}>{I18n.t('account.common.form.failed_fetching_current_status')}</Alert>
    );
  }

  return (
    <div className="change-email-form-wrapper">
      <h2>{I18n.t('account.common.form.email')}</h2>

      <div>
        {emailFormOpen && !hasPendingEmailChange && (
          <ChangeEmailForm refetchEmailVerificationStatus={recheckStatus} />
        )}

        {/* When this component mounts, we check the user's current email change status */}
        {busyFetchingCurrentStatus && <Spinner />}
        {hasPendingEmailChange && (
          <PendingEmailChangeInfo
            refetchEmailVerificationStatus={recheckStatus}
            currentEmail={emailAddress}
            pendingEmail={currentStatus!.newEmail!}
          />
        )}

        {/* Link to open the change email form is only shown if the user is not an SSO-only account */}
        {shouldShowChangeEmailLink && (
          <div>
            <div>{emailAddress}</div>
            <a href="" data-testid="open-change-email-form" onClick={handleEmailChange}>
              {I18n.t('account.common.form.change')}
            </a>
          </div>
        )}

        {/* If the user is SSO-only, we show a message instead of letting them try to change their email */}
        {userIsSsoOnly && (
          <>
            <div>{emailAddress}</div>
            <div
              className="alert warning sso-only-warning"
              dangerouslySetInnerHTML={{
                __html: I18n.t('account.common.form.sso_only_email_warning_html')
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ChangeEmailFormWrapper;
