import { get } from 'lodash';

import { defaultState } from './reducer';

export const getApiKeys = (state) => get(state, 'apiKeys', defaultState);

export const getCurrentPage = (state) => get(getApiKeys(state), 'currentPage');
export const getCurrentSearchParams = (state) => get(getApiKeys(state), 'currentSearchParams');

export const getPreviousSeeks = (state) => get(getApiKeys(state), 'previousSeeks');
export const getHasNextPage = (state) => get(getApiKeys(state), 'hasNextPage');

export const getSearchInputValue = (state) => get(getApiKeys(state), 'searchInputValue');
export const getIncludeDeletedChecked = (state) => get(getApiKeys(state), 'includeDeletedChecked');

export const getCreatedKey = (state) => get(getApiKeys(state), 'createdKey');
export const getCreateKeyError = (state) => get(getApiKeys(state), 'createKeyError');

export const getError = (state) => get(getApiKeys(state), 'error');

export const getModalsState = (state) => get(getApiKeys(state), 'modals');

export const getIsApiKeyCreateModalOpen = (state) => get(getModalsState(state), 'openCreateApiKeyModal');
