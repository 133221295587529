import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import ChangePasswordForm from './ChangePasswordForm';

export interface ChangeEmailFormWrapperProps {
  isPasswordChanged: boolean;
  passwordFormOpen: boolean;
  userIsSsoOnly: boolean;
  handlePasswordChange: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const ChangeEmailFormWrapper: FunctionComponent<ChangeEmailFormWrapperProps> = ({
  isPasswordChanged,
  passwordFormOpen,
  userIsSsoOnly,
  handlePasswordChange
}) => {
  return (
    <div className="change-password-form-wrapper">
      <h2>
        {I18n.t('account.common.form.password')}
        {isPasswordChanged && <SocrataIcon name={IconName.CheckmarkAlt} id="password-changed-icon" />}
      </h2>
      {passwordFormOpen && <ChangePasswordForm key="passwordChangeForm" />}
      {!passwordFormOpen && !userIsSsoOnly && (
        <div key="passwordPrompt">
          <a href="" data-testid="open-change-password-form" onClick={handlePasswordChange}>
            {I18n.t('account.common.form.change_password')}
          </a>
        </div>
      )}

      {userIsSsoOnly && (
        <div className="alert warning sso-only-warning">
          {I18n.t('account.common.form.sso_only_password_warning')}
        </div>
      )}
    </div>
  );
};

export default ChangeEmailFormWrapper;
