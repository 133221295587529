import { get, pick } from 'lodash';
import { defaultUserConfig } from './propTypes/configUser';

export const getConfig = (state) => get(state, 'config', {});

export const getCurrentUser = (state) => get(getConfig(state), 'currentUser', defaultUserConfig);

export const getCurrentUserEmail = (state) => get(getCurrentUser(state), 'email', '');

export const getCurrentUserUid = (state) => get(getCurrentUser(state), 'id');

export const getFormState = (state) => get(state, 'form', {});

export const getEmailFormState = (state) => get(getFormState(state), 'openEmailChangeForm', false);

export const getRouteConfig = (state) => pick(getConfig(state), ['basePath', 'routes']);

export const getRoutes = (state) => get(state, 'config.routes', {});

export const getPasswordFormState = (state) => get(getFormState(state), 'openPasswordChangeForm', false);

export const getPasswordRequirements = (state) => get(getConfig(state), 'passwordRequirements');

export const getPasswordChanged = (state) => get(getFormState(state), 'passwordChanged', false);
